import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  Chip,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import axios from "axios";
import "./submit_opportunity.css";
import {
  industry_list,
  business_type,
  stage_list,
  country_list,
} from "../../../../boilerplate/list.json";

function CompanyInfo({
  nextStep,
  formData,
  setFormData,
  user,
  companyInfo,
  setCompanyInfo,
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedIndustries, setSelectedIndustries] = useState(
    formData.companyInfoData.industries || [],
  );
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyPitchDeck, setCompanyPitchDeck] = useState("");
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    if (companyInfo && companyInfo._id) {
      const initialData = {
        companyName: companyInfo.company_name || "",
        companyType: companyInfo.company_type || "",
        companyWebsite: companyInfo.company_website || "",
        address: companyInfo.address || "",
        country: companyInfo.country || "",
        industries: companyInfo.industry || [],
        businessModel: companyInfo.business_model || "",
        companyStage: companyInfo.stage || "",
        contactPhone: companyInfo.contact_phone || "",
      };
      setFormData({ ...formData, companyInfoData: initialData });
      setSelectedIndustries(companyInfo.industry || []);
      setInitialFormData(initialData);
    }
  }, [companyInfo, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      companyInfoData: { ...formData.companyInfoData, [name]: value.trim() },
    });
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) {
      setFormData({
        ...formData,
        companyInfoData: { ...formData.companyInfoData, [name]: value.trim() },
      });
    }
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedIndustries(typeof value === "string" ? value.split(",") : value);
    setFormData({
      ...formData,
      companyInfoData: {
        ...formData.companyInfoData,
        industries: typeof value === "string" ? value.split(",") : value,
      },
    });
  };

  const handleChipDelete = (chipToDelete) => () => {
    const newIndustries = selectedIndustries.filter(
      (chip) => chip !== chipToDelete,
    );
    setSelectedIndustries(newIndustries);
    setFormData({
      ...formData,
      companyInfoData: {
        ...formData.companyInfoData,
        industries: newIndustries,
      },
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      setFormData({
        ...formData,
        companyInfoData: { ...formData.companyInfoData, [name]: file },
      });

      if (name === "companyLogo") {
        setCompanyLogo(file.name);
      } else if (name === "companyPitchDeck") {
        setCompanyPitchDeck(file.name);
      }
    }
  };

  const validateFields = () => {
    let newErrors = {};
    const requiredFields =
      companyInfo && companyInfo._id
        ? ["contactPhone", "companyWebsite", "companyStage"]
        : [
            "companyName",
            "companyType",
            "address",
            "country",
            "industries",
            "businessModel",
            "companyStage",
            "companyLogo",
            "companyPitchDeck",
          ];

    requiredFields.forEach((field) => {
      if (
        !formData.companyInfoData[field] ||
        (typeof formData.companyInfoData[field] === "string" &&
          formData.companyInfoData[field].trim().length === 0)
      ) {
        newErrors[field] = "This field is required.";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const hasFormChanged = () => {
    return Object.keys(initialFormData).some(
      (key) => initialFormData[key] !== formData.companyInfoData[key],
    );
  };

  const createOrUpdateCompany = async () => {
    try {
      const payload = {
        contact_phone:
          String(formData.companyInfoData.contactPhone).trim() || "",
        company_website:
          String(formData.companyInfoData.companyWebsite).trim() || "",
        stage: String(formData.companyInfoData.companyStage).trim() || "",
      };

      const result =
        companyInfo && companyInfo._id
          ? await axios.post(
              `/edit_company_during_application?company_id=${companyInfo._id}`,
              payload,
            )
          : await axios.post("/create_company", {
              ...payload,
              user_id: user._id,
              company_name:
                String(formData.companyInfoData.companyName).trim() || "",
              company_type:
                String(formData.companyInfoData.companyType).trim() || "",
              company_website:
                String(formData.companyInfoData.companyWebsite).trim() || "",
              address: String(formData.companyInfoData.address).trim() || "",
              country: String(formData.companyInfoData.country).trim() || "",
              industry: formData.companyInfoData.industries,
              business_model:
                String(formData.companyInfoData.businessModel).trim() || "",
            });

      console.log("Company created or updated successfully", result.data);
      setCompanyInfo(result.data.result);

      // Upload files if they are selected
      if (formData.companyInfoData.companyLogo) {
        await uploadFile(
          formData.companyInfoData.companyLogo,
          result.data.result._id,
          "avatar",
        );
      }
      if (formData.companyInfoData.companyPitchDeck) {
        await uploadFile(
          formData.companyInfoData.companyPitchDeck,
          result.data.result._id,
          "pitchdeck",
        );
      }

      nextStep();
    } catch (error) {
      console.error("Error creating or updating the company:", error);
      alert("An error occurred while creating or updating the company.");
      setSubmitting(false);
    }
  };

  const uploadFile = async (file, companyId, fileType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", fileType);
    formData.append("file_type", `company ${fileType}`);
    formData.append("object_ref", "company");
    formData.append("object_ref_value", companyId);

    try {
      const response = await axios.post(
        `/upload_file_secure?company_id=${companyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      if (response.data.status === "success") {
        console.log(
          `Updated ${fileType} url: ${response.data.result.file_url}`,
        );
      } else {
        console.error("Error uploading file:", response.data);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true); // Set submitting state at the beginning
    if (companyInfo && companyInfo._id && !hasFormChanged()) {
      // Check if only files need to be uploaded
      if (formData.companyInfoData.companyLogo) {
        await uploadFile(
          formData.companyInfoData.companyLogo,
          companyInfo._id,
          "avatar",
        );
      }
      if (formData.companyInfoData.companyPitchDeck) {
        await uploadFile(
          formData.companyInfoData.companyPitchDeck,
          companyInfo._id,
          "pitchdeck",
        );
      }
      setSubmitting(false); // Set submitting state to false after the upload is complete
      nextStep();
    } else if (validateFields()) {
      createOrUpdateCompany();
    } else {
      setSubmitting(false); // Set submitting state to false if validation fails
    }
  };

  return (
    <div className="opportunity-submission-card">
      {companyInfo && companyInfo._id ? (
        <>
          <h1>Edit Company Info</h1>
          <div className="responsive-grid">
            <div className="form-group">
              <label>
                Contact Phone<span className="required">*</span>:
              </label>
              <input
                type="text"
                name="contactPhone"
                placeholder="Enter your Contact Phone"
                value={formData.companyInfoData.contactPhone || ""}
                onChange={handlePhoneChange}
                style={{ width: "100%" }}
              />
              {errors.contactPhone && (
                <span className="error">{errors.contactPhone}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Company Website<span className="required">*</span>:
              </label>
              <input
                type="text"
                name="companyWebsite"
                placeholder="Enter your Company Website"
                value={formData.companyInfoData.companyWebsite || ""}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {errors.companyWebsite && (
                <span className="error">{errors.companyWebsite}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Company Stage<span className="required">*</span>:
              </label>
              <select
                name="companyStage"
                value={formData.companyInfoData.companyStage || ""}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Company Stage</option>
                {stage_list.map((stage) => (
                  <option key={stage.value} value={stage.value}>
                    {stage.text}
                  </option>
                ))}
              </select>
              {errors.companyStage && (
                <span className="error">{errors.companyStage}</span>
              )}
            </div>
            <div className="form-group">
              <label>Pitch Deck:</label>
              <label htmlFor="file-upload-pitch" className="custom-file-upload">
                Upload Pitch Deck
              </label>
              <input
                id="file-upload-pitch"
                type="file"
                name="companyPitchDeck"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {companyPitchDeck && (
                <span className="file-name">{companyPitchDeck}</span>
              )}
              {errors.companyPitchDeck && (
                <span className="error">{errors.companyPitchDeck}</span>
              )}
            </div>
          </div>

          <button onClick={handleSubmit} disabled={submitting}>
            {submitting ? "Submitting..." : "Save"}
          </button>
        </>
      ) : (
        <>
          <h1>Company Info</h1>
          <h3>Provide Info about the company</h3>
          <div className="responsive-grid">
            <div className="form-group">
              <label>
                Company Name<span className="required">*</span>:
              </label>
              <input
                type="text"
                name="companyName"
                placeholder="Enter your Company Name"
                value={formData.companyInfoData.companyName || ""}
                onChange={handleInputChange}
                style={{ width: "100%" }}
                required
              />
              {errors.companyName && (
                <span className="error">{errors.companyName}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Company Type<span className="required">*</span>:
              </label>
              <select
                name="companyType"
                value={formData.companyInfoData.companyType || ""}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Company Type</option>
                <option value="startup">Startup</option>
                <option value="tech-startup">Tech Startup</option>
              </select>
              {errors.companyType && (
                <span className="error">{errors.companyType}</span>
              )}
            </div>
            <div className="form-group">
              <label>Company Website:</label>
              <input
                type="text"
                name="companyWebsite"
                placeholder="Enter your Company Website"
                value={formData.companyInfoData.companyWebsite || ""}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {errors.companyWebsite && (
                <span className="error">{errors.companyWebsite}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Address<span className="required">*</span>:
              </label>
              <input
                type="text"
                name="address"
                placeholder="Enter your Address or City"
                value={formData.companyInfoData.address || ""}
                onChange={handleInputChange}
                style={{ width: "100%" }}
                required
              />
              {errors.address && (
                <span className="error">{errors.address}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Country<span className="required">*</span>:
              </label>
              <select
                name="country"
                value={formData.companyInfoData.country || ""}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Country</option>
                {country_list.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {errors.country && (
                <span className="error">{errors.country}</span>
              )}
            </div>
            <div className="form-group">
              <label>Contact Phone:</label>
              <input
                type="text"
                name="contactPhone"
                placeholder="Enter your Contact Phone"
                value={formData.companyInfoData.contactPhone || ""}
                onChange={handlePhoneChange}
                style={{ width: "100%" }}
              />
              {errors.contactPhone && (
                <span className="error">{errors.contactPhone}</span>
              )}
            </div>
            <div className="form-group">
              <InputLabel id="industries-label">
                Industry<span className="required">*</span>:
              </InputLabel>
              <FormControl fullWidth variant="outlined" required>
                <Select
                  labelId="industries-label"
                  multiple
                  value={selectedIndustries}
                  onChange={handleSelectChange}
                  renderValue={(selected) => (
                    <div className="chips">
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          onDelete={handleChipDelete(value)}
                        />
                      ))}
                    </div>
                  )}
                >
                  {industry_list.map((industry) => (
                    <MenuItem key={industry} value={industry}>
                      {industry}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.industries && (
                <span className="error">{errors.industries}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Business Model<span className="required">*</span>:
              </label>
              <select
                name="businessModel"
                value={formData.companyInfoData.businessModel || ""}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Business Model</option>
                {business_type.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {errors.businessModel && (
                <span className="error">{errors.businessModel}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Company Stage<span className="required">*</span>:
              </label>
              <select
                name="companyStage"
                value={formData.companyInfoData.companyStage || ""}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Company Stage</option>
                {stage_list.map((stage) => (
                  <option key={stage.value} value={stage.value}>
                    {stage.text}
                  </option>
                ))}
              </select>
              {errors.companyStage && (
                <span className="error">{errors.companyStage}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Company Logo<span className="required">*</span>:
              </label>
              <label htmlFor="file-upload-logo" className="custom-file-upload">
                Upload Logo
              </label>
              <input
                id="file-upload-logo"
                type="file"
                name="companyLogo"
                onChange={handleFileChange}
                style={{ display: "none" }}
                required
              />
              {companyLogo && <span className="file-name">{companyLogo}</span>}
              {errors.companyLogo && (
                <span className="error">{errors.companyLogo}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Pitch Deck<span className="required">*</span>:
              </label>
              <label htmlFor="file-upload-pitch" className="custom-file-upload">
                Upload Pitch Deck
              </label>
              <input
                id="file-upload-pitch"
                type="file"
                name="companyPitchDeck"
                onChange={handleFileChange}
                style={{ display: "none" }}
                required
              />
              {companyPitchDeck && (
                <span className="file-name">{companyPitchDeck}</span>
              )}
              {errors.companyPitchDeck && (
                <span className="error">{errors.companyPitchDeck}</span>
              )}
            </div>
          </div>

          <button onClick={handleSubmit} disabled={submitting}>
            {submitting ? "Submitting..." : "Next"}
          </button>
        </>
      )}
    </div>
  );
}

export default CompanyInfo;
