/* (2019-06-11) - This is the sidebar that is the navigation component when in
the SIR/Application Room.
*/
import React, { Component } from "react";
import URL from "url-parse";
import pbfavicon from "../../../../img/PitchBoxlogo.png";
import favicon from "../../../../img/favicon.png";
import { NavLink } from "react-router-dom";
import "./SideBar.scss";
import Tooltip from "@material-ui/core/Tooltip"
import { styled } from "@material-ui/core/styles";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      old_url: "",
      highlighted_explore: false,
      highlighted_opportunity: false,
    };
    //this.state.url.pathname
  }
  
  render() {

    const StyledTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        zIndex: theme.zIndex.tooltip + 1,
      //margin: 4,
      [`& .MuiTooltip-tooltip`]: {
        maxWidth: 200,
        fontFamily: "'Grape Nuts', Helvetica",
        backgroundColor: "rgba(0,0,0,1)",
        fontSize:"15px",
        margin: 15,
        padding: 8,
        whiteSpace: "pre-line"
        //border: "solid yellow 1px"
      }
    }));
    
    return (
      <div className="__application-sidebar">
         <div className="__pitchbox-menu-header">
              {/* <img className="h-1" src={pbfavicon}></img> */}
               <h2 className="ml-4">PITCHBOX</h2>
          </div>

        <div className="__main">
          <div>
          {/* <NavLink
                  className={`${this.state.url.pathname === "/applicant_room_feed" ? "active" : ""}`}
                  to={
                    "/applicant_room_feed?opportunity_id=" +
                    this.state.url.query.opportunity_id
                  }
                  id={
                    this.state.url.pathname === "/applicant_room_feed"
                      ? "submission"
                      : "sidetabs"
                  }
                  ><StyledTooltip
                  title="Scout Deals (Beta)" 
                  placement="right"
                  >
                  <i className="material-icons sidebar_icon">home</i>
                  </StyledTooltip>
                  <div className="__drop-downlinks-handler"><p>Feed</p></div>
                </NavLink> */}
                  <NavLink
                    className={`${this.state.url.pathname === "/applicant_room_dash" ? "active" : ""}`}
                    to={
                      "/applicant_room_dash?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room_"
                        ? "submission"
                        : "sidetabs"
                    }
                    >
                    {/* <StyledTooltip
                    title="Dashboard" 
                    placement="right"
                    >
                    
                    
                    </StyledTooltip> */}
                    <i className="material-icons sidebar_icon">analytics</i> 
                    <div className="__drop-downlinks-handler"><p>Dashboard</p></div>
                  </NavLink>
                  <NavLink
                    className={`${this.state.url.pathname === "/applicant_room" ? "active" : ""}`}
                    to={
                      "/applicant_room?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room"
                        ? "submission"
                        : "sidetabs"
                    }
                   ><StyledTooltip
                   title="Deals" 
                   placement="right"
                   >
                    <i className="material-icons sidebar_icon">tornado</i>
                    </StyledTooltip>
                    <div className="__drop-downlinks-handler"><p>Deals</p></div>
                  </NavLink>
                  <NavLink
                    className={`${this.state.url.pathname === "/applicant_room_top" ? "active" : ""}`}
                    to={
                      "/applicant_room_top?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room_top"
                        ? "submission"
                        : "sidetabs"
                    }
                   ><StyledTooltip
                   title="Reviews" 
                   placement="right"
                   >
                    <i className="material-icons sidebar_icon">star</i>
                    </StyledTooltip>
                    <div className="__drop-downlinks-handler"><p>Reviews</p></div>
                  </NavLink>
                  {/* {(this.props.opportunity_owner._id === this.props.user._id ||
              this.props.user.email === "admin@startupfuel.com") && ( */}
              {/* This features allows an admin with the above login to make changes to client PitchBoxes */}
                <NavLink
                  className={`${this.state.url.pathname === "/applicant_room_team" ? "active" : ""}`}
                  to={
                    "/applicant_room_team?opportunity_id=" +
                    this.state.url.query.opportunity_id
                  }
                  id={
                    this.state.url.pathname === "/applicant_room_team"
                      ? "submission"
                      : "sidetabs"
                  }
                ><StyledTooltip
                title="Team" 
                placement="right"
                >
                  <i className="material-icons sidebar_icon">group</i>
                  </StyledTooltip>
                  <div className="__drop-downlinks-handler"><p>Team</p></div>
                </NavLink>
                {/*<NavLink
                  className={`${this.state.url.pathname === "/applicant_room_referral_partners" ? "active" : ""}`}
                  to={
                    "/applicant_room_referral_partners?opportunity_id=" +
                    this.state.url.query.opportunity_id
                  }
                  id={
                    this.state.url.pathname ===
                    "/applicant_room_referral_partners"
                      ? "submission"
                      : "sidetabs"
                  }
                  ><StyledTooltip
                  title="Network" 
                  placement="right"
                  >
                  <i className="material-icons white-text">handshake</i>
                  </StyledTooltip>
                </NavLink> */}
                <NavLink
                  to={
                    "/opportunity?opportunity_id=" +
                    this.state.url.query.opportunity_id
                  }
                  target="_blank" rel="noreferrer"
                  id={
                    this.state.url.pathname ===
                    "/opportunity"
                      ? "submission"
                      : "sidetabs"
                  }
                  ><StyledTooltip
                  title="Portal" 
                  placement="right"
                  >
                  <i className="material-icons sidebar_icon">preview</i>
                  </StyledTooltip>
                  <div className="__drop-downlinks-handler"><p>Portal</p></div>
                </NavLink>
                <NavLink
                  className={`${this.state.url.pathname === "/applicant_room_explore" ? "active" : ""}`}
                  to={
                    "/applicant_room_explore?opportunity_id=" +
                    this.state.url.query.opportunity_id
                  }
                  id={
                    this.state.url.pathname === "/applicant_room_explore"
                      ? "submission"
                      : "sidetabs"
                  }
                  ><StyledTooltip
                  title="Scout Deals (Beta)" 
                  placement="right"
                  >
                  <i className="material-icons sidebar_icon">search</i>
                  </StyledTooltip>
                  <div className="__drop-downlinks-handler"><p>Scout</p></div>
                </NavLink>
                
                
                   <div className="__pitchbox-menu-footer">
                    {/* <div className="bg-black p-3 text-black border-2 border-black">
                      <h6>Need help?</h6>
                      <p>Check out our documentation</p>
                    </div> */}
                   <div className="__border"></div>
                    <a href="/explore" target="_blank" rel="noreferrer">
                      <div className="__drop-downlinks-handler"><p>StartupFuel</p></div>
                    </a>
                    <NavLink
                      className={`${ ( 
                        (this.state.url.pathname === "/applicant_room_settings") ||
                        (this.state.url.pathname === "/applicant_room_settings_judges") ||
                        (this.state.url.pathname === "/applicant_room_settings_reviews") ||
                        (this.state.url.pathname === "/applicant_room_settings_applicants") ||
                        (this.state.url.pathname === "/applicant_room_settings_visibility")
                      )  ? "active" : ""}`}
                      to={
                        "/applicant_room_settings_judges?opportunity_id=" +
                        this.state.url.query.opportunity_id
                      }
                      id={
                        this.state.url.pathname ===
                        "/applicant_room_settings_judges"
                          ? "submission"
                          : "sidetabs"
                      }
                    ><StyledTooltip
                    title="Settings" 
                    placement="right"
                    >
                      <i className="material-icons sidebar_icon">settings</i>
                      </StyledTooltip>
                      <div className="__drop-downlinks-handler"><p>Settings</p></div>
                    </NavLink>
                   </div>
                </div>
        </div>        
      </div>
    );
  }
}

export default SideBar;


