/* (2019-06-10) - This is a massive popups file that contains the majority of the
popups used on the site. It should definitely be broken down and simplified.
*/
import React, { Fragment } from "react";
import { FormControl, FormLabel, Row, Col, Card, Table } from "react-bootstrap";
import Button from "@material-ui/core/Button";

import update from "immutability-helper";
import URL from "url-parse";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
} from "../../boilerplate/list.json";
import popupPic from "../../img/rocket.png";
import developmentPic from "../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../App";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import waterfall from "async/waterfall";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import { SocialIcon } from "react-social-icons";
import VideoIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";
import Geocode from "react-geocode";
import "./popup_ubaid.scss";
import "./PopupsCoverPhoto.css";
import "./oppHeader.css";
import "./profiles/picture.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  media: {
    height: 140,
  },
  developmentMedia: {
    maxWidth: "200px",
    height: "200px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    margin: 0,
  },
});

export const withContext = (WrappedComponent, context) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }
    static contextType = context;
    render() {
      return <WrappedComponent {...this.props} {...this.context} />;
    }
  };
};

export const Company_Update = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div class="container">
        <div className="form-gorup">
          <FormLabel className="control-label">Profile Photo</FormLabel>
          <FormControl
            type="file"
            data-file-type="avatar"
            accept=".gif,.jpg,.jpeg,.png"
            placeholder="Company Name"
            name="avatar"
            onChange={props.handleFileUpload}
            className="form-control mb-3"
          />
        </div>
        <div className="form-gorup">
          <FormLabel className="control-label">Background Photo</FormLabel>
          <FormControl
            type="file"
            name="background"
            accept=".gif,.jpg,.jpeg,.png"
            data-file-type="background"
            onChange={props.handleFileUpload}
            className="form-control mb-3"
          />
        </div>
        <div className="form-gorup">
          <FormLabel className="control-label">Company Name</FormLabel>
          <FormControl
            type="text"
            value={props.company_name}
            placeholder="Company Name"
            name="company_name"
            onChange={props.handleChange}
            className="form-control mb-3"
          />
        </div>

        <div className="form-gorup">
          <FormLabel>Company Type</FormLabel>
          <FormControl
            componentclass="select"
            placeholder="select"
            name="company_type"
            onChange={props.handleChange}
          >
            {company_type_list.map((type) => (
              <option
                selected={props.company_type === type ? true : false}
                value={type}
              >
                {type}
              </option>
            ))}
          </FormControl>
        </div>
        <br />

        <FormLabel className="control-label">Short Bio</FormLabel>
        <FormControl
          //type="textarea"
          style={{ height: "100px" }}
          componentclass="textarea"
          value={props.short_bio}
          placeholder="Please provide a short biography"
          name="short_bio"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel className="control-label">Company Website</FormLabel>
        <FormControl
          type="text"
          value={props.company_website}
          placeholder="www.yourwebsite.com"
          name="company_website"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel className="control-label">Street Number</FormLabel>
        <FormControl
          type="number"
          value={props.street_number}
          placeholder="Street Number"
          name="street_number"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel className="control-label">Street Name</FormLabel>
        <FormControl
          type="text"
          value={props.street_name}
          placeholder="Street Name"
          name="street_name"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel className="control-label">City Name</FormLabel>
        <FormControl
          type="text"
          value={props.city}
          placeholder="City Name"
          name="city"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel className="control-label">Province</FormLabel>
        <FormControl
          type="text"
          value={props.province}
          placeholder="Province Name"
          name="province"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel className="control-label">Country</FormLabel>
        <FormControl
          type="text"
          value={props.country}
          placeholder="Country"
          name="country"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel>Business Model</FormLabel>
        <FormControl
          componentclass="select"
          placeholder="select"
          name="business_model"
          onChange={props.handleChange}
        >
          {business_type.map((type) => (
            <option
              selected={props.business_model === type ? true : false}
              value={type}
            >
              {type}
            </option>
          ))}
        </FormControl>
        <br />

        <div className="form-gorup">
          <FormLabel>Industry</FormLabel>
          <FormControl
            componentclass="select"
            name="industry"
            multiple
            placeholder="select"
            onChange={props.handleChangeMultiple}
          >
            {industry_list.sort().map((industry) => (
              <option
                selected={
                  Array.isArray(props.industry) &&
                  props.industry.some((co_ind) => co_ind === industry)
                }
                value={industry}
              >
                {industry}
              </option>
            ))}
          </FormControl>
        </div>
        <br />

        <div className="form-gorup">
          <FormLabel>Stage</FormLabel>
          <FormControl
            componentclass="select"
            name="stage"
            placeholder="select"
            onChange={props.handleChange}
          >
            {stage_list.map((stage) => (
              <option
                selected={props.stage === stage.value ? true : false}
                value={stage.value}
              >
                {stage.text}
              </option>
            ))}
          </FormControl>
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={props.handleInputSubmit}
        >
          Save
        </button>
      </div>

      <div class="container card-footer">
        <button type="button" class="cancelbtn" onClick={props.dismiss}>
          Cancel
        </button>
      </div>
    </form>
  </div>
);

export class Opportunity_Company_Update1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),

      email: "",
      password: "",
      first_name: "",
      last_name: "",
      company_name: "",
      company_type: "",
      industry: ["Fintech"],
      stage: 1,
      business_model: "B2B",
      facebook: "",
      company_website: "",
      pitch_comp_video_link: "",
      twitter: "",
      linkedIn: "",
      instagram: "",
      pitch_video_link: "",
      old_pitch_video_link: "",
      process_pitch_video: "",
      custom_text_q1: "",
      custom_text_q2: "",
      custom_text_a1: "",
      custom_text_a2: "",
      iOS_app_url: "",
      android_app_url: "",

      looking_for: "", //opportunitiies, opportunity type, raising funds, purpose, etc
      objective: "",
      profitable: "",
      problem: "",
      target_market: "",
      market_size: "",
      solution: "",
      competitors: "",
      differentiation: "",
      revenue_model: "",
      revenue_since_inception: "",
      growth: "",
      traction: "",
      marketing_strategy: "",
      team_description: "",
      short_bio: "",
      pitchdeckDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },

      attempting_to_raise: 0,
      last_raise: "",
      evaluation: 0,
      date_of_inception: "",
      month_of_inception: "",
      year_of_inception: "",

      contact_email: "",
      contact_phone: "",

      general_err_message: "",

      lat: 0,
      long: 0,
      address: "",
      old_address: "",
      formatted_address: "",
      location_processed: 0,
      formIsValids: false,
      errors: {},
      old_address: "",

      incubator_accelerator_history: "",
      raising_funds: "",
      settings: {
        weeklyOpportunities: true,
        weeklyServices: true,
      },
      showButtonLoader: false,

      sub_vertical: "",
      finance_mtl_q1: "",
      finance_mtl_q2: "",
      finance_mtl_q3: "",
      finance_mtl_q4: "",

      pitchdeckDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
    };
  }

  componentDidMount() {
    console.log(this.state);
    axios
      .get(
        `fetch_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`
      )
      .then((result) => {
        // this.setState({
        //   ...result.data.result[0]
        // })
      });
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "address") {
          this.getLocation();
        }
      }
    );
  };

  handleFileUpload = (e) => {
    let file = e.target.files[0];
    let that = this;
    if (e.target.dataset.fileType === "pitchdeck" && this.props.isLoggedIn) {
      this.setState({
        pitchdeckDetails: {
          file,
          file_name: "pitchdeck",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.props.companies_owned[0].company,
        },
      });
    } else if (
      e.target.dataset.fileType === "pitchdeck" &&
      !this.props.isLoggedIn
    ) {
      this.setState({
        pitchdeckDetails: {
          file,
          file_name: "pitchdeck",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
        },
      });
    }
  };

  handleFileSubmit = (type, company_id) => {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state[type]) {
      formData.append(key, this.state[type][key]);
    }
    if (typeof this.state[type].file === "object") {
      axios
        .post(
          `/upload_file_secure?company_id=${this.state.pitchdeckDetails.object_ref_value}`,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status === "success") {
            if (
              (type === "avatarDetails" &&
                this.state.company_files.some(
                  (file) => file.file_type === "company avatar"
                )) ||
              (type === "backgroundDetails" &&
                this.state.company_files.some(
                  (file) => file.file_type === "company background"
                )) ||
              (type === "pitchdeckDetails" &&
                this.state.company_files.some(
                  (file) => file.file_type === "company pitchdeck"
                ))
            ) {
              //if (this.state.company_files.length > 0){
              let index = this.state.company_files.findIndex(
                (item) => item.file_type === response.data.result.file_type
              );
              index = index === -1 ? this.state.company_files.length : index;
              this.state.company_files
                .filter(
                  (item_1) =>
                    item_1.file_type === response.data.result.file_type
                )
                .map((item_2) => {
                  this.setState({
                    company_files: update(this.state.company_files, {
                      [index]: {
                        file_url: {
                          $set: response.data.result.file_url,
                        },
                      },
                    }),
                    // [type]: {
                    //   file: '',
                    //   file_name: '',
                    //   file_type: ''
                    // }
                    showEditPopup: false,
                  });
                });
            } else {
              this.setState({
                company_files: [
                  ...this.state.company_files,
                  ...[
                    {
                      file_type: response.data.result.file_type,
                      file_url: response.data.result.file_url,
                    },
                  ],
                ],

                showEditPopup: false,
              });
            }
          } // do else later
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  saveAndSubmit = (e) => {
    e.preventDefault();
    this.handleFileSubmit("pitchdeckDetails");
    const ratings = this.props.ratings;
    console.log("get the props", ...this.props.ratings);

    let company_id = this.props.companies_owned[1][0]._id;
    axios
      .post(`/edit_company_during_application?company_id=${company_id}`, {
        ...this.state,
        ratings,

        uid: this.props.user._id,
      })
      .then((response) => {
        this.props.handleApplicationPopup();
        this.props.handleAlertPopupApplication();
        this.props.opportunityApplication(this.props.user._id);
        this.props.create_opportunity_application_referral(
          this.props.user._id,
          this.props.companies_owned[1][0]._id
        );
        axios.post(
          `/delete_opportunity_lead?opportunity_id=${this.props.url.query.opportunity_id}`,
          {
            interested_user: this.props.user._id,
          }
        );
      });
  };
  saveAnswers = (e, i) => {
    let company_id = this.props.companies_owned[1][0]._id;
    axios
      .post(`/edit_company_during_application?company_id=${company_id}`, {
        ...this.state,
        uid: this.props.user._id,
      })
      .then(() => {
        this.setState({
          saved: i,
        });
      });
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleValidation = (event) => {
    this.setState({
      showButtonLoader: !this.state.showButtonLoader,
    });
    if (
      this.state.pitch_comp_video_link !== undefined &&
      this.state.pitch_comp_video_link !== null &&
      this.state.pitch_comp_video_link.length > 0 &&
      this.state.pitch_comp_video_link.includes("=")
    ) {
      const url_arr = this.state.pitch_comp_video_link.split("=");
      this.setState({
        pitch_comp_video_link: "https://www.youtube.com/embed/" + url_arr[1],
      });
    } else if (
      this.state.pitch_comp_video_link != undefined &&
      this.state.pitch_comp_video_link !== null &&
      this.state.pitch_comp_video_link.length > 0 &&
      this.state.pitch_comp_video_link.includes("youtu.be")
    ) {
      const url_arr = this.state.pitch_comp_video_link.split("/");
      this.setState({
        pitch_comp_video_link: "https://www.youtube.com/embed/" + url_arr[3],
      });
    }
    let errors = {};
    let formIsValid = true;

    if (!this.props.isLoggedIn) {
      if (this.state.first_name.length === 0) {
        formIsValid = false;
        errors["first"] = " Please Enter an your first Name";
      }
      if (this.state.last_name.length === 0) {
        formIsValid = false;
        errors["last"] = " Please Enter an your last Name";
      }
      if (this.state.password.length === 0) {
        formIsValid = false;
        errors["password"] = " Cannot be empty";
      }

      if (this.state.email.length === 0) {
        formIsValid = false;
        errors["email"] = " Cannot be empty";
      }

      if (typeof this.state.email !== "undefined") {
        let lastAtPos = this.state.email.lastIndexOf("@");
        let lastDotPos = this.state.email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            this.state.email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            this.state.email.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors["email"] =
            " Email is not valid, please structure it like 'user@example.com'";
        }
      }
    }
    if (
      this.props.companies_owned === null ||
      this.props.companies_owned === undefined
    ) {
      if (this.state.company_name.length === 0) {
        formIsValid = false;
        errors["company_name"] = " Cannot be empty";
      }
      if (this.state.company_type.length === 0) {
        formIsValid = false;
        errors["company_type"] = " Cannot be empty";
      }
      if (this.state.industry.length === 0) {
        formIsValid = false;
        errors["industry"] = " Cannot be empty";
      }
      if (this.state.stage === 1) {
        formIsValid = false;
        errors["company_stage"] = " Cannot be empty";
      }
      if (this.state.address.length === 0) {
        formIsValid = false;
        errors["location"] = " Cannot be empty";
      }
    }

    // For people that have already registered
    if (this.state.contact_phone.length === 0) {
      formIsValid = false;
      errors["contact_phone"] = " Cannot be empty";
    }
    // if (this.state.sub_vertical.length === 0) {
    //   formIsValid = false;
    //   errors["sub_vertical"] = " Cannot be empty";
    // }
    //
    // if (this.state.linkedIn.length === 0) {
    //   formIsValid = false;
    //   errors["linkedIn"] = " Cannot be empty";
    // }
    // if (this.state.company_website.length === 0) {
    //   formIsValid = false;
    //   errors["company_website"] = " Cannot be empty";
    // }
    for (var opp_form_key in this.props.opportunity_form) {
      if (
        this.state[opp_form_key]?.length === 0 &&
        this.props.opportunity_form[opp_form_key]["required"] == true
      ) {
        formIsValid = false;
        errors[opp_form_key] = " Cannot be empty";
      }
    }

    // if(this.state.short_bio.length === 0){
    //  formIsValid = false;
    //  errors["short_bio"] = " Cannot be empty";
    // }
    // if(this.state.objective.length === 0){
    //  formIsValid = false;
    //  errors["objective"] = " Cannot be empty";
    // }

    this.setState(
      {
        errors: errors,
      },
      () => {
        if (formIsValid) {
          this.setState(
            {
              formIsValids: true,
              general_err_message: "",
              showButtonLoader: !this.state.showButtonLoader,
            },
            () => {
              if (!this.props.isLoggedIn) {
                this.registerAndSubmit(event);
              } else if (
                this.props.isLoggedIn &&
                (this.props.companies_owned === null ||
                  this.props.companies_owned === undefined)
              ) {
                this.create_company_submit_application(event);
              } else {
                this.saveAndSubmit(event);
              }
            }
          );
        } else {
          this.setState({
            general_err_message: "Please fix the errors above!",
            showButtonLoader: !this.state.showButtonLoader,
          });
        }
      }
    );
  };

  handleUserValidation = (event) => {
    event.preventDefault();

    let errors = {};
    let formIsValid = true;

    if (this.state.first_name.length === 0) {
      formIsValid = false;
      errors["first"] = " Please Enter an your first Name";
    }
    if (this.state.last_name.length === 0) {
      formIsValid = false;
      errors["last"] = " Please Enter an your last Name";
    }
    if (this.state.password.length === 0) {
      formIsValid = false;
      errors["password"] = " Cannot be empty";
    }

    if (this.state.email.length === 0) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }

    if (typeof this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] =
          " Email is not valid, please structure it like 'user@example.com'";
      }
    }
    this.setState({
      errors: errors,
    });
    if (formIsValid) {
      this.setState({
        formIsValids: true,
      });
      return waterfall(
        [
          (callback) => {
            axios
              .post("/create_user", {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                password: this.state.password,
              })
              .then((result) => {
                if (result.data.status === "existing user") {
                  let errors = {};
                  let formIsValid = true;
                  errors["email"] = " User already exists";
                  this.setState({
                    errors: errors,
                  });
                }
                if (result.data.status !== "existing user") {
                  callback(null, result.data);
                  //document.location.reload(true);
                }
                this.setState({
                  showButtonLoader: false,
                });
              });
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          console.log("done", done);
          this.props.persistUser(done.data);
          axios.post(
            `/create_opportunity_lead?opportunity_id=${this.props.url.query.opportunity_id}`,
            {
              interested_user: done.data._id,
              email_lead: done.data.email,
              applicant_first_name: done.data.first_name,
              applicant_last_name: done.data.last_name,
            }
          );
        }
      );
    }
  };
  handleCompanyValidation = (event) => {
    // console.log("company validation" + userInfo);
    if (
      this.state.pitch_comp_video_link.length > 0 &&
      this.state.pitch_comp_video_link.includes("=")
    ) {
      const url_arr = this.state.pitch_comp_video_link.split("=");
      this.setState({
        pitch_comp_video_link: "https://www.youtube.com/embed/" + url_arr[1],
      });
    } else if (
      this.state.pitch_comp_video_link.length > 0 &&
      this.state.pitch_comp_video_link.includes("youtu.be")
    ) {
      const url_arr = this.state.pitch_comp_video_link.split("/");
      this.setState({
        pitch_comp_video_link: "https://www.youtube.com/embed/" + url_arr[3],
      });
    }
    this.setState({
      showButtonLoader: !this.state.showButtonLoader,
    });
    let errors = {};
    let formIsValid = true;

    if (this.state.company_name.length === 0) {
      formIsValid = false;
      errors["company_name"] = " Cannot be empty";
    }
    if (this.state.company_type.length === 0) {
      formIsValid = false;
      errors["company_type"] = " Cannot be empty";
    }
    if (this.state.industry.length === 0) {
      formIsValid = false;
      errors["industry"] = " Cannot be empty";
    }
    if (this.state.stage === 1) {
      formIsValid = false;
      errors["company_stage"] = " Cannot be empty";
    }
    if (this.state.address.length === 0) {
      formIsValid = false;
      errors["location"] = " Cannot be empty";
    }

    this.setState({
      errors: errors,
    });

    if (formIsValid) {
      waterfall(
        [
          // get user info with its id
          (callback) => {
            axios
              .post("/create_company", {
                user_id: this.props.user._id,
                company_name: this.state.company_name,
                company_type: this.state.company_type,
                street_number: this.state.street_number,
                short_bio: this.state.short_bio,
                website: this.state.website,
                street_name: this.state.street_number,
                address: this.state.address,
                city: this.state.city,
                province: this.state.province,
                country: this.state.country,
                industry: this.state.industry,
                stage: this.state.stage,
                business_model: this.state.business_model,
                address: this.state.address,
                formatted_address: this.state.formatted_address,
              })
              .then((result) => {
                callback(null, {
                  company: result.data,
                });
              });
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          console.log("this.props.user", this.props.user);
          this.props.persistUser(this.props.user);
          this.setState({
            showButtonLoader: false,
          });
          axios.post(
            `/update_opportunity_lead?opportunity_id=${this.props.url.query.opportunity_id}`,
            {
              interested_user: this.props.user._id,
              company: done.company.result._id,
              company_name: done.company.result.company_name,
            }
          );
          //this.props.history.push(`profile?company_id=${done.company.result._id}`)
        }
      );
    }
  };

  registerAndSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValids) {
      waterfall(
        [
          (callback) => {
            axios
              .post("/create_user", {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                password: this.state.password,
              })
              .then((result) => {
                if (result.data.status === "existing user") {
                  let errors = {};
                  let formIsValid = true;
                  errors["email"] = " User already exists";
                  this.setState({
                    errors: errors,
                  });
                }
                if (result.data.status !== "existing user") {
                  callback(null, result.data);
                }
              });
          },
          (user, callback) => {
            axios
              .post("/create_company", {
                user_id: user._id,
                company_name: this.state.company_name,
                company_type: this.state.company_type,
                short_bio: this.state.short_bio,
                street_name: this.state.street_number,
                address: this.state.address,
                city: this.state.city,
                province: this.state.province,
                ratings: this.props.ratings,
                country: this.state.country,
                industry: this.state.industry,
                stage: this.state.stage,
                business_model: this.state.business_model,
                address: this.state.address,
                formatted_address: this.state.formatted_address,
                objective: this.state.objective,
                problem: this.state.problem,
                target_market: this.state.target_market,
                market_size: this.state.market_size,
                solution: this.state.solution,
                competitors: this.state.competitors,
                differentiation: this.state.differentiation,
                revenue_model: this.state.revenue_model,
                revenue_since_inception: this.state.revenue_since_inception,
                growth: this.state.growth,
                traction: this.state.traction,
                marketing_strategy: this.state.marketing_strategy,
                team_description: this.state.team_description,
                sub_vertical: this.state.sub_vertical,
                company_website: this.state.company_website,
                pitch_comp_video_link: this.state.pitch_comp_video_link,
                linkedIn: this.state.linkedIn,

                contact_phone: this.state.contact_phone,
                incubator_accelerator_history:
                  this.state.incubator_accelerator_history,
                raising_funds: this.state.raising_funds,

                finance_mtl_q1: this.state.finance_mtl_q1,
                finance_mtl_q2: this.state.finance_mtl_q2,
                finance_mtl_q3: this.state.finance_mtl_q3,
                finance_mtl_q4: this.state.finance_mtl_q4,
              })
              .then((result) => {
                callback(null, {
                  user: user.data,
                  company: result.data,
                });
              });
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          console.log("done 10520,", done);
          this.props.persistUser(done.user);
          axios.post("/create_opportunity_application", {
            applicant_company: done.company.result._id,
            pertains_to_opportunity: this.props.url.query.opportunity_id,
            should_send: this.props.has_applicant_registration_email,
            changed_by: this.props.user._id,
            send_to: done.user.email,
            header: this.props.applicant_registration_email.header,
            body: this.props.applicant_registration_email.body,
          });
          this.setState(
            {
              pitchdeckDetails: {
                ...this.state.pitchdeckDetails,
                object_ref_value: done.company.result._id,
              },
            },
            () => {
              this.handleFileSubmit("pitchdeckDetails");
            }
          );
          this.props.handleApplicationPopup();
          this.props.handleAlertPopupApplication();
          this.props.create_opportunity_application_referral(
            done.user._id,
            done.company.result._id
          );
        }
      );
    }
  };
  create_company_submit_application = (e) => {
    e.preventDefault();
    console.log("this is the props", this.props);
    axios
      .post("/create_company", {
        user_id: this.props.user._id,
        company_name: this.state.company_name,
        company_type: this.state.company_type,
        short_bio: this.state.short_bio,
        street_name: this.state.street_number,
        address: this.state.address,
        city: this.state.city,
        province: this.state.province,
        country: this.state.country,
        industry: this.state.industry,
        stage: this.state.stage,
        business_model: this.state.business_model,
        address: this.state.address,
        formatted_address: this.state.formatted_address,
        objective: this.state.objective,
        problem: this.state.problem,
        ratings: this.state.ratings,
        target_market: this.state.target_market,
        market_size: this.state.market_size,
        solution: this.state.solution,
        competitors: this.state.competitors,
        differentiation: this.state.differentiation,
        revenue_model: this.state.revenue_model,
        revenue_since_inception: this.state.revenue_since_inception,
        growth: this.state.growth,
        traction: this.state.traction,
        marketing_strategy: this.state.marketing_strategy,
        team_description: this.state.team_description,
        sub_vertical: this.state.sub_vertical,
        company_website: this.state.company_website,
        pitch_comp_video_link: this.state.pitch_comp_video_link,
        linkedIn: this.state.linkedIn,

        contact_phone: this.state.contact_phone,
        incubator_accelerator_history: this.state.incubator_accelerator_history,
        raising_funds: this.state.raising_funds,

        finance_mtl_q1: this.state.finance_mtl_q1,
        finance_mtl_q2: this.state.finance_mtl_q2,
        finance_mtl_q3: this.state.finance_mtl_q3,
        finance_mtl_q4: this.state.finance_mtl_q4,
      })
      .then((response) => {
        axios.post("/create_opportunity_application", {
          applicant_company: response.data.result._id,
          pertains_to_opportunity: this.props.url.query.opportunity_id,
          changed_by: this.props.user_id,
        });
        this.setState(
          {
            pitchdeckDetails: {
              ...this.state.pitchdeckDetails,
              object_ref_value: response.data.result._id,
            },
          },
          () => {
            this.handleFileSubmit("pitchdeckDetails");
            this.props.handleAlertPopupApplication();
          }
        );
      });
  };

  componentDidUpdate(prevProps) {
    console.log("this.props.companies_owned", this.props.companies_owned);
    if (
      Array.isArray(this.props.companies_owned) &&
      prevProps.companies_owned !== this.props.companies_owned &&
      this.props.companies_owned[1] !== undefined &&
      this.props.companies_owned[1][0]._id !== undefined
    ) {
      axios
        .get(
          `fetch_company_profile?company_id=${this.props.companies_owned[1][0]._id}`
        )
        .then((result) => {
          this.setState({
            ...result.data.result[0],
          });
        });
    }
  }
  getLocation = () => {
    Geocode.setApiKey("AIzaSyAo8kBbOGTAr2C5kFNic3fZYUxA7KYNp7M");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    // Get latidude & longitude from address.
    if (this.state.old_address !== this.state.address) {
      this.setState(
        {
          old_address: this.state.address,
        },
        () => {
          Geocode.fromAddress(this.state.address).then(
            (response) => {
              const address_array =
                response.results[0].formatted_address.split(", ");
              const { lat, lng } = response.results[0].geometry.location;
              if (address_array.length === 4) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[1],
                  province: address_array[2],
                  country: address_array[3],
                  formatted_address: response.results[0].formatted_address,
                });
              } else if (address_array.length === 3) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[0],
                  province: address_array[1],
                  country: address_array[2],
                  formatted_address: response.results[0].formatted_address,
                });
              }
            },
            (error) => {
              console.error(error);
            }
          );
        }
      );
    }
  };

  render() {
    window.pagesense = window.pagesense || [];
    window.pagesense.push(["trackEvent", "Application Submission"]);
    const { classes } = this.props;
    return (
      <div
        style={
          this.props.show === true
            ? {
                display: "block",
              }
            : {
                display: "none",
              }
        }
        onClick={this.props.dismissAppModal}
        class="modal1"
      >
        <form class="modal-content animate" style={{ paddingBottom: "10px" }}>
          <div class="container">
            {console.log("this.props 1237", this.props)}
            <h3 class="text-center" style={{ marginTop: "20px" }}>
              {this.props.opportunity_name} Application
            </h3>
            <p style={{ textAlign: "center", fontStyle: "italic" }}>
              {this.state.company_name.length !== 0 ? (
                <></>
              ) : (
                <span>
                  By applying for this application you are also registering for
                  StartupFuel.
                </span>
              )}
            </p>
            <div style={{ width: "95%", margin: "auto" }}>
              {!this.props.isLoggedIn && (
                <form id="applicant-user">
                  <div>
                    <h3 className="text-center">Account Owner Info:</h3>
                    <hr />
                    <div className="form-gorup">
                      <FormLabel className="control-label">
                        First Name
                      </FormLabel>
                      <span className="text-danger">
                        {this.state.errors["first"]}
                      </span>
                      <FormControl
                        type="text"
                        value={this.state.first_name}
                        placeholder="First Name"
                        name="first_name"
                        onChange={this.handleChange}
                        className="form-control mb-3"
                      />
                    </div>
                    <FormLabel className="control-label">Last Name</FormLabel>
                    <span className="text-danger">
                      {this.state.errors["last"]}
                    </span>
                    <FormControl
                      type="text"
                      value={this.state.last_name}
                      placeholder="Last Name"
                      name="last_name"
                      onChange={this.handleChange}
                      className="form-control mb-3"
                    />
                    <FormLabel className="control-label">Email</FormLabel>
                    <span className="text-danger">
                      {this.state.errors["email"]}
                    </span>
                    <FormControl
                      type="email"
                      value={this.state.email}
                      placeholder="User Email"
                      name="email"
                      onChange={this.handleChange}
                      className="form-control mb-3"
                    />
                    <FormLabel className="control-label">Password</FormLabel>
                    <span className="text-danger">
                      {this.state.errors["password"]}
                    </span>
                    <FormControl
                      type="password"
                      value={this.state.password}
                      placeholder="User Password"
                      name="password"
                      onChange={this.handleChange}
                      className="form-control mb-3"
                    />
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      sm={8}
                      style={{ margin: "auto" }}
                    >
                      <button
                        type="button"
                        class="btn btn-primary col-12"
                        onClick={this.handleUserValidation}
                      >
                        SAVE & NEXT
                      </button>
                    </Grid>
                  </div>
                </form>
              )}
              {(this.props.companies_owned === null ||
                this.props.companies_owned === undefined) &&
                this.props.isLoggedIn && (
                  <form id="applicant-company">
                    <div>
                      <h3 className="text-center">Company Info:</h3>
                      <hr />
                      <div className="form-gorup">
                        <FormLabel className="control-label">
                          Company Name
                        </FormLabel>
                        <span className="text-danger">
                          {this.state.errors["company_name"]}
                        </span>
                        <FormControl
                          type="text"
                          value={this.state.company_name}
                          placeholder="Company Name"
                          name="company_name"
                          onChange={this.handleChange}
                          className="form-control mb-3"
                        />
                      </div>
                      <div className="form-gorup">
                        <FormLabel className="control-label">
                          Company Type
                        </FormLabel>
                        <span className="text-danger">
                          {this.state.errors["company_type"]}
                        </span>
                        <br />
                        <Select
                          value={this.state.company_type}
                          onChange={this.handleChange}
                          input={<Input id="select-multiple-chip" />}
                          name="company_type"
                          style={{ display: "inherit" }}
                          className={classes.textField}
                          renderValue={(selected) => (
                            <div>
                              <MenuItem
                                key={this.state.company_type}
                                value={this.state.company_type}
                              >
                                {this.state.company_type}
                              </MenuItem>
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {company_type_list.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        <br />
                        <FormLabel className="control-label">
                          Company HQ (If you do not have an office address
                          array, just enter the city you reside in.)
                        </FormLabel>
                        <span className="text-danger">
                          {this.state.errors["location"]}
                        </span>
                        <br />
                        <Icon
                          className={classNames(
                            classes.iconHover,
                            "fas fa-map-marker-alt"
                          )}
                          color="error"
                          style={{
                            fontSize: 30,
                            margin: "3px",
                            width: "50px",
                            paddingLeft: "15px",
                          }}
                        />
                        <input
                          type="text"
                          style={{ width: "80%" }}
                          name="address"
                          value={this.state.address}
                          onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <InputLabel htmlFor="select-multiple-chip">
                          Industries
                        </InputLabel>
                        <span className="text-danger">
                          {this.state.errors["industry"]}
                        </span>
                        <br />
                        <Select
                          multiple
                          value={this.state.industry}
                          onChange={this.handleChange}
                          input={<Input id="select-multiple-chip" />}
                          name="industry"
                          style={{ width: "95%" }}
                          className={classes.textField}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {industry_list.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={this.state.industry.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        <div className="form-gorup">
                          <br />
                          <FormLabel className="control-label">
                            Company Stage
                          </FormLabel>
                          <span className="text-danger">
                            {this.state.errors["company_stage"]}
                          </span>
                          <br />
                          <Select
                            value={this.state.stage}
                            onChange={this.handleChange}
                            input={<Input id="select-multiple-chip" />}
                            name="stage"
                            style={{ display: "inherit" }}
                            className={classes.textField}
                            renderValue={(selected) => (
                              <div>
                                <MenuItem
                                  key={this.state.stage}
                                  value={this.state.stage}
                                >
                                  {this.getStage(this.state.stage, stage_list)}
                                </MenuItem>
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {stage_list.map((name) => (
                              <MenuItem key={name.text} value={name.value}>
                                {name.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        sm={8}
                        style={{ margin: "auto" }}
                      >
                        <button
                          type="button"
                          class="btn btn-primary col-12"
                          onClick={this.handleCompanyValidation}
                        >
                          SAVE & NEXT
                        </button>
                      </Grid>
                    </div>
                  </form>
                )}
              {!(
                this.props.companies_owned === null ||
                this.props.companies_owned === undefined
              ) &&
                this.props.isLoggedIn && (
                  <form id="questions-form">
                    <div>
                      <FormLabel className="control-label">
                        Enter a contact phone number.
                      </FormLabel>
                      <span className="text-danger">
                        {this.state.errors["contact_phone"]}
                      </span>
                      <br />
                      <Icon
                        className={classNames(
                          classes.iconHover,
                          "fas fa-phone"
                        )}
                        color="success"
                        style={{
                          fontSize: 30,
                          margin: "3px",
                          width: "50px",
                          paddingLeft: "15px",
                        }}
                      />
                      <input
                        type="text"
                        style={{ width: "80%" }}
                        name="contact_phone"
                        value={this.state.contact_phone}
                        onChange={this.handleChange}
                      />
                      <br />
                      {this.props.has_judge_specialty && (
                        <div>
                          <br />
                          <FormLabel className="control-label">
                            Sub Vertical
                          </FormLabel>
                          <span className="text-danger">
                            {this.state.errors["sub_vertical"]}
                          </span>
                          <br />
                          <Select
                            value={this.state.sub_vertical}
                            onChange={this.handleChange}
                            input={<Input id="select-multiple-chip" />}
                            name="sub_vertical"
                            style={{ display: "inherit" }}
                            className={classes.textField}
                            renderValue={(selected) => (
                              <div>
                                <MenuItem
                                  key={this.state.sub_vertical}
                                  value={this.state.sub_vertical}
                                >
                                  {this.state.sub_vertical}
                                </MenuItem>
                              </div>
                            )}
                            MenuProps={MenuProps}
                          >
                            {vertical_list.map((name) => (
                              <MenuItem key={name} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                          <br />
                        </div>
                      )}

                      <span className="text-danger">
                        {this.state.errors["linkedIn"]}
                      </span>
                      <FormLabel className="control-label">
                        Founder/CEO's LinkedIn
                      </FormLabel>
                      <br />
                      <SocialIcon
                        style={{ margin: "3px" }}
                        id="linkedIn-social-oppheader"
                        url="linkedin.com"
                      />
                      <input
                        type="text"
                        name="linkedIn"
                        id="linkedIn-box-oppheader"
                        style={{ width: "90%" }}
                        value={this.state.linkedIn}
                        placeholder="https://www.linkedin.com/in/myName/"
                        onChange={this.handleChange}
                      />
                      {this.props.enter_company_website && (
                        <div className="form-gorup">
                          <FormLabel className="control-label">
                            Company Website
                          </FormLabel>
                          <span className="text-danger">
                            {this.state.errors["company_website"]}
                          </span>
                          <FormControl
                            type="text"
                            value={this.state.company_website}
                            placeholder="www.website.com"
                            name="company_website"
                            onChange={this.handleChange}
                            className="form-control mb-3"
                          />
                        </div>
                      )}
                      {this.props.enter_pitch_video && (
                        <div className="form-gorup">
                          <br />

                          <FormLabel className="control-label">
                            Youtube Pitch Link -{" "}
                            <strong>
                              In a 1 minute video, please describe what your
                              company does, why you founded it and what makes
                              you unique as a founder.
                            </strong>
                          </FormLabel>
                          <FormControl
                            type="text"
                            value={this.state.pitch_comp_video_link}
                            placeholder="https://www.youtube.com/watch?v=rnlIE9i10_Y"
                            name="pitch_comp_video_link"
                            onChange={this.handleChange}
                            className="form-control mb-3"
                          />
                        </div>
                      )}
                      <p style={{ textAlign: "center", fontStyle: "italic" }}>
                        The information filled out below will be private on your
                        profile (unless you change your settings).
                      </p>
                      <div className="form-gorup">
                        <FormLabel className="control-label">
                          Pitch Deck or Resume
                        </FormLabel>
                        <span className="text-danger">
                          {this.state.errors["pitchdeckDetails"]}
                        </span>
                        <FormControl
                          type="file"
                          data-file-type="pitchdeck"
                          placeholder="pitchdeck"
                          accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                          name="pitchdeck"
                          onChange={this.handleFileUpload}
                          className="form-control mb-3"
                        />
                      </div>
                      {Object.keys(this.props.opportunity_form).map(
                        (opp_form_key, i) => (
                          <div>
                            {this.props.opportunity_form[opp_form_key][
                              "display"
                            ] === true && (
                              <div>
                                <TextField
                                  id="outlined-multiline-flexible"
                                  label={
                                    this.props.opportunity_form[opp_form_key][
                                      "title"
                                    ]
                                  }
                                  multiline
                                  placeholder={
                                    this.props.opportunity_form[opp_form_key][
                                      "helper_text"
                                    ]
                                  }
                                  rows="5"
                                  value={this.state[opp_form_key]}
                                  onChange={this.handleChange}
                                  name={opp_form_key}
                                  className={classes.textField}
                                  margin="normal"
                                  fullWidth={true}
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: 300,
                                  }}
                                />
                                <span className="text-danger">
                                  {this.state.errors[opp_form_key]}
                                </span>
                                <span style={{ float: "right" }}>
                                  {this.state[opp_form_key]?.length}/300
                                </span>
                                {this.state.saved !== undefined &&
                                  this.state.saved === i && (
                                    <span className="text-success">
                                      Answer Saved!
                                    </span>
                                  )}

                                {/* <button
                                    type="button"
                                    class="btn btn-primary col-12"
                                    onClick={(event) =>
                                      this.saveAnswers(event, i)
                                    }
                                  >
                                    SAVE ANSWER
                                  </button> */}

                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    {this.props.isLoggedIn &&
                      this.props.companies_owned !== null && (
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          sm={8}
                          style={{ margin: "auto" }}
                        >
                          <Button
                            type="button"
                            class="btn btn-primary col-12"
                            onClick={this.handleValidation}
                            disabled={this.state.showButtonLoader}
                          >
                            {this.state.showButtonLoader
                              ? "Submitting..."
                              : "Submit Application"}
                          </Button>
                        </Grid>
                      )}
                  </form>
                )}
            </div>

            {/* (this.props.isLoggedIn && this.props.companies_owned === null) &&
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                sm={ 8 }
                style={ { margin: "auto" } }>
                <button
                  type="button"
                  class="btn btn-primary col-12"
                  onClick={ this.handleValidation }>
                  CREATE COMPANY & SUBMIT APPLICATION
                </button>
              </Grid> */}
            {/* !this.props.isLoggedIn &&
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                sm={ 8 }
                style={ { margin: "auto" } }>
                <button
                  type="button"
                  class="btn btn-primary col-12"
                  onClick={ this.handleValidation }>
                  REGISTER & SUBMIT APPLICATION
                </button>
              </Grid>*/}
            <span
              style={{ color: "red", textAlign: "center", display: "block" }}
            >
              {this.state.general_err_message}
            </span>
          </div>
        </form>
      </div>
    );
  }
}

export class User_Profile_Photo_Update extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: "",
    };
  }
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
    this.props.handleFileUpload(e);
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img style={{ height: "100%" }} src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }
    return (
      <div
        style={
          this.props.show === true ? { display: "block" } : { display: "none" }
        }
        onClick={this.props.dismiss}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="container">
            <div className="form-gorup">
              <FormLabel className="control-label">
                Profile Photo - Please upload a photo with equal dimensions. (ex
                400 x 400)
              </FormLabel>

              <FormControl
                type="file"
                data-file-type="user avatar"
                placeholder="Profile Photo"
                accept=".gif,.jpg,.jpeg,.png"
                name="user avatar"
                onChange={(e) => this._handleImageChange(e)}
                className="form-control mb-3"
              />
            </div>
            <div className="previewComponent">
              <div className="imgPreview PopProfileImage">{$imagePreview}</div>
            </div>
            <button
              className="btn btn-primary"
              name="userAvatarDetails"
              type="submit"
              onClick={this.props.handleFileSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export class Profile_Photo_Update extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: "",
    };
  }
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
    this.props.handleFileUpload(e);
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img style={{ height: "100%" }} src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }
    return (
      <div
        style={
          this.props.show === true ? { display: "block" } : { display: "none" }
        }
        onClick={this.props.dismiss}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="container">
            <div className="form-gorup">
              <FormLabel className="control-label">Profile Photo</FormLabel>
              <FormControl
                type="file"
                data-file-type="avatar"
                accept=".gif,.jpg,.jpeg,.png"
                placeholder="Company Name"
                name="avatar"
                onChange={(e) => this._handleImageChange(e)}
                className="form-control mb-3"
              />
            </div>
            <div className="previewComponent">
              <div className="imgPreview">{$imagePreview}</div>
            </div>
            <button
              className="btn btn-primary"
              name="showProfilePhotoPopup"
              type="submit"
              onClick={this.props.handleInputSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export class Cover_Photo_Update extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: "",
      displaySelect: true,
      displayVideo: false,
      displayPicture: false,
      background_video_link: "",
    };
  }
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
    this.props.handleFileUpload(e);
  }
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleButtonClick = (e, name) => {
    e.preventDefault();
    if (name !== "displaySelect") {
      this.setState({
        displaySelect: false,
        [name]: true,
      });
    } else {
      this.setState({
        displaySelect: true,
        displayVideo: false,
        displayPicture: false,
        background_video_link: "",
      });
    }
  };

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img style={{ height: "100%" }} src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }
    return (
      <div
        style={
          this.props.show === true ? { display: "block" } : { display: "none" }
        }
        onClick={this.props.dismiss}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="container UbaidCompCovCon">
            {this.state.displaySelect && (
              <Row
                style={{ padding: "30px 0" }}
                className="justify-content-md-center am"
              >
                <Col>
                  <Card>
                    <Card.Body style={{ paddingTop: "40px", margin: "0" }}>
                      <div
                        className="title"
                        style={{ color: "cadetblue" }}
                        onClick={(event) =>
                          this.handleButtonClick(event, "displayPicture")
                        }
                      >
                        <ImageIcon style={{ fontSize: 100 }} />
                        <Card.Title>
                          <b>Background Image</b>
                        </Card.Title>
                      </div>
                      <div>
                        <Card.Text
                          style={{ textAlign: "center", paddingTop: "5px" }}
                        >
                          <div style={{ textAlign: "center" }}></div>
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <Card.Body style={{ paddingTop: "40px", margin: "0" }}>
                      <div
                        className="title"
                        style={{ color: "cornflowerblue" }}
                        onClick={(event) =>
                          this.handleButtonClick(event, "displayVideo")
                        }
                      >
                        <VideoIcon style={{ fontSize: 100 }} />
                        <Card.Title>
                          <b>Background Video</b>
                        </Card.Title>
                      </div>
                      <div>
                        <Card.Text
                          style={{ textAlign: "center", paddingTop: "5px" }}
                        >
                          <div style={{ textAlign: "center" }}></div>
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.displayPicture && (
              <div>
                <div className="form-gorup">
                  <FormLabel className="control-label">Cover Photo</FormLabel>
                  <FormControl
                    type="file"
                    accept=".gif,.jpg,.jpeg,.png"
                    data-file-type="background"
                    placeholder="Company Name"
                    name="avatar"
                    onChange={(e) => this._handleImageChange(e)}
                    className="form-control mb-3"
                  />
                </div>
                <div className="previewComponent UBaidpreviewComponent">
                  <div className="imgPreview">{$imagePreview}</div>
                </div>

                <button
                  className="btn btn-primary"
                  type="submit"
                  name="showCoverPhotoPopup"
                  onClick={this.props.handleInputSubmit}
                >
                  Save
                </button>
                <button
                  className="btn btn-secondary"
                  type="submit"
                  name="showCoverPhotoPopup"
                  onClick={(event) =>
                    this.handleButtonClick(event, "displaySelect")
                  }
                >
                  Back
                </button>
              </div>
            )}

            {this.state.displayVideo && (
              <div>
                <Card.Title>
                  Please enter a youtube link this will be your background.
                </Card.Title>
                <TextField
                  id="outlined-multiline-static"
                  style={{ width: "90%" }}
                  value={this.state.background_video_link}
                  name="background_video_link"
                  placeholder="https://www.youtube.com/watch?v=rnlIE9i10_Y"
                  margin="normal"
                  onChange={this.handleChange}
                  variant="outlined"
                />
                <button
                  className="btn btn-primary"
                  onClick={(event) =>
                    this.props.handleBackgroundVideo(
                      event,
                      this.state.background_video_link
                    )
                  }
                >
                  Save
                </button>
                <button
                  className="btn btn-secondary"
                  type="submit"
                  name="showCoverPhotoPopup"
                  onClick={(event) =>
                    this.handleButtonClick(event, "displaySelect")
                  }
                >
                  Back
                </button>
              </div>
            )}
          </div>
          {/*
      <div class="container card-footer">
        <button type="button" class="cancelbtn" onClick={this.props.dismiss}>Cancel</button>
      </div>
      */}
        </form>
      </div>
    );
  }
}

export const Invite_User = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div class="container">
        <div className="form-gorup">
          <FormLabel className="control-label">First Name</FormLabel>
          <FormControl
            type="text"
            value={props.first_name}
            placeholder="First Name"
            name="first_name"
            onChange={props.handleChange}
            className="form-control mb-3"
          />
        </div>

        <FormLabel className="control-label">Last Name</FormLabel>
        <FormControl
          type="text"
          value={props.last_name}
          placeholder="Last Name"
          name="last_name"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <FormLabel className="control-label">Email</FormLabel>
        <FormControl
          type="email"
          value={props.email}
          placeholder="User Email"
          name="email"
          onChange={props.handleChange}
          className="form-control mb-3"
        />

        <Button
          type="submit"
          onClick={props.handleInputSubmit}
          className="btn btn-primary btn-lg btn-block login-button"
        >
          Submit
        </Button>
      </div>

      <div class="container text-center">
        <span class="psw">
          <a href="/create_user">Create New User</a>
        </span>
      </div>
    </form>
  </div>
);

const Sign_In_Comp = (props) => (
  <div
    style={
      props.showSignInPopup && !props.isLoggedIn
        ? { display: "block" }
        : { display: "none" }
    }
    onClick={(e) => props.dismissModal(e, "showSignInPopup")}
    class="modal1"
  >
    <form class="modal-content animate">
      {(props.loginError !== null || props.loginError !== undefined) && (
        <span className="text-danger">{props.loginError}</span>
      )}
      <div class="container" style={{ padding: "30px" }}>
        <FormLabel>Email</FormLabel>
        <FormControl
          type="email"
          value={props.email}
          placeholder="User Email"
          name="email"
          onChange={props.handleChange}
          className="form-control mb-3 block-example border"
        />

        <FormLabel>Password</FormLabel>
        <FormControl
          type="password"
          value={props.password}
          placeholder="User Password"
          name="password"
          onChange={props.handleChange}
          className="form-control mb-3 block-example border"
        />
        <div className="my-3">
          <button
            className="btn btn-primary btn-lg btn-block"
            type="submit"
            onClick={props.handleAuth}
          >
            Login
          </button>
          <p
            style={{ color: "blue" }}
            className="login-forgot-pass-btn"
            onClick={props.toggleForgotPassPopup}
          >
            Forgot Password?
          </p>
        </div>
      </div>

      {/*<div class="container text-center">
        <span class="psw"><a href="/create_user">Create New User</a></span>
      </div>*/}
    </form>
  </div>
);

export const Opportunity = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div class="container">
        <div className="form-gorup">
          <FormLabel className="control-label">Background Photo</FormLabel>
          <FormControl
            type="file"
            accept=".gif,.jpg,.jpeg,.png"
            name="background"
            data-file-type="background"
            onChange={props.handleFileUpload}
            className="form-control mb-3"
          />
        </div>
        <div className="form-gorup">
          <FormLabel className="control-label">Opportunity Name</FormLabel>
          <FormControl
            type="text"
            value={props.opportunity_name}
            placeholder="Opportunity Name"
            name="opportunity_name"
            onChange={props.handleChange}
            className="form-control mb-3"
          />
        </div>

        <div className="form-gorup">
          <FormLabel className="control-label">Opportunity Name</FormLabel>
          <FormControl
            type="text"
            value={props.opportunity_name}
            placeholder="Opportunity Name"
            name="opportunity_name"
            onChange={props.handleChange}
            className="form-control mb-3"
          />
        </div>

        <div className="form-gorup">
          <FormLabel>Industry</FormLabel>
          <FormControl
            componentclass="select"
            name="industry"
            multiple
            placeholder="select"
            onChange={props.handleChangeMultiple}
          >
            {industry_list.sort().map((industry) => (
              <option
                selected={
                  Array.isArray(props.industry) &&
                  props.industry.some((co_ind) => co_ind === industry)
                }
                value={industry}
              >
                {industry}
              </option>
            ))}
          </FormControl>
        </div>
        <br />

        <div className="form-gorup">
          <FormLabel>Stage</FormLabel>
          <FormControl
            componentclass="select"
            name="stage"
            placeholder="select"
            onChange={props.handleChange}
          >
            {stage_list.map((stage) => (
              <option
                selected={props.stage === stage.value ? true : false}
                value={stage.value}
              >
                {stage.text}
              </option>
            ))}
          </FormControl>
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={props.handleInputSubmit}
        >
          Save
        </button>
      </div>

      <div class="container card-footer">
        <button type="button" class="cancelbtn" onClick={props.dismiss}>
          Cancel
        </button>
      </div>
    </form>
  </div>
);

export class Stay_In_Touch_Comp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsValids: false,
      errors: {},
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleValidation = (event) => {
    let errors = {};
    let formIsValid = true;

    if (this.props.email_lead.length === 0) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }

    if (typeof this.props.email_lead !== "undefined") {
      let lastAtPos = this.props.email_lead.lastIndexOf("@");
      let lastDotPos = this.props.email_lead.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.props.email_lead.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.props.email_lead.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] =
          " Email is not valid, please structure it like 'user@example.com'";
      }
    }

    this.setState({ errors: errors }, () => {
      if (formIsValid) {
        this.setState({
          formIsValids: true,
        });

        this.props.handleInputSubmit(event);
      }
    });
  };

  render() {
    return (
      <div
        style={
          this.props.showSignUpPopup
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => this.props.dismissModal(e, "showSignUpPopup")}
        class="modal1"
      >
        <form class="modal-content animate" style={{ paddingBottom: "10px" }}>
          <CardMedia
            image={popupPic}
            title="Rocket"
            className={this.props.classes.media}
          />
          <div class="container">
            <p className={this.props.classes.p}>
              Don't miss out on new opportunities that are posted on the site.
              Before we redirect you to this opportunity's page, give us your
              name, email and we will let you know of the latest opportunities.
            </p>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={this.props.classes.container}
            >
              <TextField
                id="standard-name"
                label="First Name"
                name="first_name"
                value={this.props.first_name}
                onChange={this.props.handleChange}
                margin="normal"
                className={this.props.classes.textField}
              />
              <TextField
                id="standard-name"
                label="Last Name"
                name="last_name"
                value={this.props.last_name}
                onChange={this.props.handleChange}
                margin="normal"
                className={this.props.classes.textField}
              />
            </Grid>

            <TextField
              id="standard-email-input"
              label="Email"
              type="email"
              name="email_lead"
              value={this.props.email_lead}
              autoComplete="email_lead"
              onChange={this.props.handleChange}
              margin="normal"
              fullWidth
              className={this.props.classes.textField}
            />
            <span className="text-danger">{this.state.errors["email"]}</span>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <FormLabel
                control={
                  <Checkbox
                    checked={this.props.interests.funding}
                    value="funding"
                    onChange={this.props.handleCheckMarkChange("funding")}
                    name="funding"
                  />
                }
                label="Funding"
              />
              <FormLabel
                control={
                  <Checkbox
                    checked={this.props.interests.coaching}
                    value="coaching"
                    onChange={this.props.handleCheckMarkChange("coaching")}
                    name="coaching"
                  />
                }
                label="Coaching"
              />
              <FormLabel
                control={
                  <Checkbox
                    checked={this.props.interests.pitch_competition}
                    value="pitch_competition"
                    onChange={this.props.handleCheckMarkChange(
                      "pitch_competition"
                    )}
                    name="pitch_competition"
                  />
                }
                label="Pitch Competition"
              />
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <FormLabel
                control={
                  <Checkbox
                    checked={this.props.interests.community}
                    value="community"
                    onChange={this.props.handleCheckMarkChange("community")}
                    name="community"
                  />
                }
                label="Community"
              />
              <FormLabel
                control={
                  <Checkbox
                    checked={this.props.interests.office_space}
                    value="office_space"
                    onChange={this.props.handleCheckMarkChange("office_space")}
                    name="office_space"
                  />
                }
                label="Office Space"
              />
              <FormLabel
                control={
                  <Checkbox
                    checked={this.props.interests.education}
                    value="community"
                    onChange={this.props.handleCheckMarkChange("education")}
                    name="education"
                  />
                }
                label="Education"
              />
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="left"
                sm={4}
              >
                <button
                  type="button"
                  class="btn btn-outline-dark col-10"
                  onClick={this.props.handleInputSkip}
                >
                  SKIP
                </button>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                sm={8}
              >
                <button
                  type="button"
                  class="btn btn-primary col-12"
                  onClick={this.handleValidation}
                >
                  SUBMIT & GO
                </button>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    );
  }
}

export class Register_Comp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      company_name: "",
      formIsValids: false,
      errors: {},
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleValidation = (event) => {
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.first_name.length === 0) {
      formIsValid = false;
      errors["first"] = " Please Enter an your first Name";
    }
    if (this.state.last_name.length === 0) {
      formIsValid = false;
      errors["last"] = " Please Enter an your last Name";
    }
    if (this.state.password.length === 0) {
      formIsValid = false;
      errors["password"] = " Cannot be empty";
    }
    if (this.state.company_name.length === 0) {
      formIsValid = false;
      errors["company_name"] = " Cannot be empty";
    }

    if (this.state.email.length === 0) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }

    if (typeof this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] =
          " Email is not valid, please structure it like 'user@example.com'";
      }
    }

    this.setState({ errors: errors }, () => {
      if (formIsValid) {
        this.setState({
          formIsValids: true,
        });

        this.handleInputSubmit(event);
      }
    });
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValids) {
      waterfall(
        [
          (callback) => {
            axios
              .post("/create_user", {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                password: this.state.password,
                company_name: this.state.company_name,
              })
              .then((result) => {
                if (result.data.status === "existing user") {
                  let errors = {};
                  let formIsValid = true;
                  errors["email"] = " User already exists";
                  this.setState({ errors: errors });
                }
                if (result.data.status !== "existing user") {
                  callback(null, result.data);
                }
              });
          },
          (user, callback) => {
            axios
              .post("/create_company", {
                user_id: user._id,
                company_name: this.state.company_name,
              })
              .then((result) =>
                callback(null, { user: user.data, company: result.data })
              );
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          this.props.persistUser(done.user);
          this.props.toggleRegisterPopup();
        }
      );
    }
  };

  render() {
    return (
      <div
        style={
          this.props.showRegisterPopup
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => this.props.dismissModal(e, "showRegisterPopup")}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="container" style={{ padding: "30px" }}>
            <div className="form-gorup">
              <FormLabel className="control-label">First Name</FormLabel>
              <span className="text-danger">{this.state.errors["first"]}</span>
              <FormControl
                type="text"
                value={this.state.first_name}
                //className={this.props.classes.textField}
                placeholder="First Name"
                name="first_name"
                onChange={this.handleChange}
                className="form-control mb-3"
              />
            </div>

            <FormLabel className="control-label">Last Name</FormLabel>
            <span className="text-danger">{this.state.errors["last"]}</span>
            <FormControl
              type="text"
              value={this.state.last_name}
              placeholder="Last Name"
              name="last_name"
              onChange={this.handleChange}
              className="form-control mb-3"
            />

            <FormLabel className="control-label">Email</FormLabel>
            <span className="text-danger">{this.state.errors["email"]}</span>
            <FormControl
              type="email"
              value={this.state.email}
              placeholder="User Email"
              name="email"
              onChange={this.handleChange}
              className="form-control mb-3"
            />

            <FormLabel className="control-label">Password</FormLabel>
            <span className="text-danger">{this.state.errors["password"]}</span>
            <FormControl
              type="password"
              value={this.state.password}
              placeholder="Password"
              name="password"
              onChange={this.handleChange}
              className="form-control mb-3"
            />

            <FormLabel className="control-label">Company Name</FormLabel>
            <span className="text-danger">
              {this.state.errors["company_name"]}
            </span>
            <FormControl
              type="text"
              value={this.state.company_name}
              placeholder="Company Name"
              name="company_name"
              onChange={this.handleChange}
              className="form-control mb-3"
            />
            <Button
              onClick={this.handleValidation}
              className="btn btn-primary btn-lg btn-block login-button"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

//opportunities page
export class Share_Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl: `${window.location.hostname}/opportunity?opportunity_id=${this.props._id}`,
      //window.location.hostname+'/opportunity?opportunity_id='+this.props._id}
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.opportunity_id !== this.props.opportunity_id &&
      this.props.user._id === undefined
    ) {
      this.setState({
        shareUrl: `${window.location.hostname}/opportunity?opportunity_id=${this.props._id}`,
      });
    } else if (
      prevProps.opportunity_id !== this.props.opportunity_id &&
      this.props.user !== undefined &&
      this.props.user._id !== undefined
    ) {
      this.setState({
        shareUrl: `${window.location.hostname}/opportunity?opportunity_id=${this.props._id}&referrer_id=${this.props.user._id}`,
      });
    }
  }

  render() {
    const element = (
      <div class="text-center">
        <a
          href={
            "mailto:?subject=Check%20out%20this%20opportunity%20on%20StartupFuel%20&amp;body=" +
            window.location.hostname +
            "/opportunity?opportunity_id=" +
            this.props._id
          }
          class="fa fa-2x fa-envelope"
        ></a>

        <a
          href={
            "https://facebook.com/sharer/sharer.php?u=" +
            window.location.hostname +
            "/opportunity?opportunity_id=" +
            this.props._id
          }
          class="fa fa-2x fa-facebook"
        ></a>

        <a
          href={
            "https://twitter.com/intent/tweet/?text=" +
            window.location.hostname +
            "/opportunity?opportunity_id=" +
            this.props._id
          }
          class="fa fa-2x fa-twitter"
        ></a>

        <a
          href={
            "https://www.linkedin.com/shareArticle?mini=true&amp;url=" +
            window.location.hostname +
            "/opportunity?opportunity_id=" +
            this.props._id
          }
          class="fa fa-2x fa-linkedin"
        ></a>

        <a
          href={
            "https://reddit.com/submit/?url=" +
            window.location.hostname +
            "/opportunity?opportunity_id=" +
            this.props._id
          }
          class="fa fa-2x fa-reddit"
        ></a>
      </div>
    );
    return (
      <div
        style={
          this.props.showSharePopup ? { display: "block" } : { display: "none" }
        }
        class="modal1"
      >
        <form class="modal-content animate __share-modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Share This Opportunity!
            </h5>
            <button
              type="button"
              onClick={this.props.handleSharePopup}
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div className="form-gorup col-12 text-center">
              <FormLabel className="control-label">Copy Link</FormLabel>
              <FormControl
                as="textarea"
                value={this.state.shareUrl}
                className="form-control mb-3"
              />
            </div>

            <div>{element}</div>
          </div>
        </form>
      </div>
    );
  }
}

export class Explore_Share_Popup extends React.Component {
  render() {
    let element = (
      <div class="text-center">
        <a
          href={
            "mailto:?subject=Check%20out%20this%20opportunity%20on%20StartupFuel%20&amp;body=" +
            window.location.hostname +
            "/profile?company_id=" +
            this.props._id
          }
          class="fa fa-2x fa-envelope"
        ></a>

        <a
          href={
            "https://facebook.com/sharer/sharer.php?u=" +
            window.location.hostname +
            "/profile?company_id=" +
            this.props._id
          }
          class="fa fa-2x fa-facebook"
        ></a>

        <a
          href={
            "https://twitter.com/intent/tweet/?text=" +
            window.location.hostname +
            "/profile?company_id=" +
            this.props._id
          }
          class="fa fa-2x fa-twitter"
        ></a>

        <a
          href={
            "https://www.linkedin.com/shareArticle?mini=true&amp;url=" +
            window.location.hostname +
            "/profile?company_id=" +
            this.props._id
          }
          class="fa fa-2x fa-linkedin"
        ></a>

        <a
          href={
            "https://reddit.com/submit/?url=" +
            window.location.hostname +
            "/profile?company_id=" +
            this.props._id
          }
          class="fa fa-2x fa-reddit"
        ></a>
      </div>
    );
    return (
      <div
        style={
          this.props.showSharePopup
            ? { display: "block", overflow: "hidden" }
            : { display: "none" }
        }
        onClick={this.props.dismiss}
        class="modal1"
      >
        <form class="modal-content animate share-modal profile-share-popup">
          <div class="modal-header text-center">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Share Company
            </h5>
          </div>

          <div class="modal-body">
            <div className="form-gorup col-12 text-center">
              <FormLabel className="control-label">Copy Link</FormLabel>
              <FormControl
                type="text"
                value={
                  window.location.hostname +
                  "/profile?company_id=" +
                  this.props._id
                }
                className="form-control mb-3"
              />
            </div>

            <div>{element}</div>
          </div>
        </form>
      </div>
    );
  }
}

export class Opportunity_Judge_Share extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl:
        window.location.hostname +
        "/opportunity?opportunity_id=" +
        this.props.url.query.opportunity_id +
        "&referrer_id=" +
        this.props.user._id,
      //window.location.hostname+'/opportunity?opportunity_id='+this.props._id}
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.user !== undefined &&
      prevProps.user._id !== this.props.user._id
    ) {
      this.setState({
        shareUrl:
          window.location.hostname +
          "/opportunity?opportunity_id=" +
          this.props.url.query.opportunity_id +
          "&referrer_id=" +
          this.props.user._id,
      });
    }
  }
  render() {
    const element = (
      <div class="text-center">
        <a
          href={
            "mailto:?subject=Check%20out%20this%20opportunity%20on%20StartupFuel%20&amp;body=" +
            this.state.shareUrl
          }
          class="fa fa-2x fa-envelope"
          target="_blank"
        ></a>

        <a
          href={
            "https://facebook.com/sharer/sharer.php?u=" + this.state.shareUrl
          }
          class="fa fa-2x fa-facebook"
          target="_blank"
        ></a>

        <a
          href={"https://twitter.com/intent/tweet/?text=" + this.state.shareUrl}
          class="fa fa-2x fa-twitter"
          target="_blank"
        ></a>

        <a
          href={
            "https://www.linkedin.com/shareArticle?mini=true&amp;url=" +
            this.state.shareUrl
          }
          class="fa fa-2x fa-linkedin"
          target="_blank"
        ></a>

        <a
          href={"https://reddit.com/submit/?url=" + this.state.shareUrl}
          class="fa fa-2x fa-reddit"
          target="_blank"
        ></a>
      </div>
    );
    return (
      <div
        style={
          this.props.showSharePopup ? { display: "block" } : { display: "none" }
        }
        onClick={this.props.handleSharePopup}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Share This Opportunity!
            </h5>
            <button
              type="button"
              onClick={this.props.handleSharePopup}
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div className="form-gorup col-12 text-center">
              <FormLabel className="control-label">Copy Link</FormLabel>
              <FormControl
                as="textarea"
                value={this.state.shareUrl}
                className="form-control mb-3"
              />
            </div>

            <div>{element}</div>
          </div>
        </form>
      </div>
    );
  }
}

//opportunity page
export class Share_Popup_Opp_Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LinkedInUrl:
        "https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fstartupfuel.herokuapp.com%2Fopportunity%3Fopportunity_id%3D5c421275c587520029f900f4&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;summary=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;source=https%3A%2F%2Fstartupfuel.herokuapp.com%2Fopportunity%3Fopportunity_id%3D5c421275c587520029f900f4",
      shareUrl: `${this.props.url.href}`,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.user !== undefined &&
      this.props.user._id !== undefined &&
      prevProps.user !== this.props.user
    ) {
      this.setState({
        shareUrl: `${this.props.url.href}&referrer_id=${this.props.user._id}`,
      });
    }
  }

  render() {
    const element = (
      <div>
        <a
          class="resp-sharing-button__link"
          href={
            "https://facebook.com/sharer/sharer.php?u=" +
            window.location.hostname +
            "/opportunity?opportunity_id=" +
            this.props._id
          }
          target="_blank"
          rel="noopener"
          aria-label="Share on Facebook"
        >
          <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--large">
            <div
              aria-hidden="true"
              class="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
              </svg>
            </div>
            Share on Facebook
          </div>
        </a>

        <a
          class="resp-sharing-button__link"
          href={
            "https://twitter.com/intent/tweet/?text=" +
            window.location.hostname +
            "/opportunity?opportunity_id=" +
            this.props._id
          }
          target="_blank"
          rel="noopener"
          aria-label="Share on Twitter"
        >
          <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--large">
            <div
              aria-hidden="true"
              class="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
              </svg>
            </div>
            Share on Twitter
          </div>
        </a>

        <a
          class="resp-sharing-button__link"
          href={
            "mailto:?subject=Check%20out%20this%20opportunity%20on%20StartupFuel%20&amp;body=" +
            this.props.url.href
          }
          target="_self"
          rel="noopener"
          aria-label="Share by E-Mail"
        >
          <div class="resp-sharing-button resp-sharing-button--email resp-sharing-button--large">
            <div
              aria-hidden="true"
              class="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z" />
              </svg>
            </div>
            Share by E-Mail
          </div>
        </a>
        <a
          class="resp-sharing-button__link"
          href={this.state.LinkedInUrl}
          target="_blank"
          rel="noopener"
          aria-label="Share on LinkedIn"
        >
          <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--large">
            <div
              aria-hidden="true"
              class="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z" />
              </svg>
            </div>
            Share on LinkedIn
          </div>
        </a>

        <a
          class="resp-sharing-button__link"
          href={"https://reddit.com/submit/?url=" + this.props.url.href}
          target="_blank"
          rel="noopener"
          aria-label="Share on Reddit"
        >
          <div class="resp-sharing-button resp-sharing-button--reddit resp-sharing-button--large">
            <div
              aria-hidden="true"
              class="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M24 11.5c0-1.65-1.35-3-3-3-.96 0-1.86.48-2.42 1.24-1.64-1-3.75-1.64-6.07-1.72.08-1.1.4-3.05 1.52-3.7.72-.4 1.73-.24 3 .5C17.2 6.3 18.46 7.5 20 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.38 0-2.54.94-2.88 2.22-1.43-.72-2.64-.8-3.6-.25-1.64.94-1.95 3.47-2 4.55-2.33.08-4.45.7-6.1 1.72C4.86 8.98 3.96 8.5 3 8.5c-1.65 0-3 1.35-3 3 0 1.32.84 2.44 2.05 2.84-.03.22-.05.44-.05.66 0 3.86 4.5 7 10 7s10-3.14 10-7c0-.22-.02-.44-.05-.66 1.2-.4 2.05-1.54 2.05-2.84zM2.3 13.37C1.5 13.07 1 12.35 1 11.5c0-1.1.9-2 2-2 .64 0 1.22.32 1.6.82-1.1.85-1.92 1.9-2.3 3.05zm3.7.13c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9.8 4.8c-1.08.63-2.42.96-3.8.96-1.4 0-2.74-.34-3.8-.95-.24-.13-.32-.44-.2-.68.15-.24.46-.32.7-.18 1.83 1.06 4.76 1.06 6.6 0 .23-.13.53-.05.67.2.14.23.06.54-.18.67zm.2-2.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5.7-2.13c-.38-1.16-1.2-2.2-2.3-3.05.38-.5.97-.82 1.6-.82 1.1 0 2 .9 2 2 0 .84-.53 1.57-1.3 1.87z" />
              </svg>
            </div>
            Share on Reddit
          </div>
        </a>
      </div>
    );
    return (
      <div
        style={
          this.props.showSharePopup ? { display: "block" } : { display: "none" }
        }
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Share This Opportunity!
            </h5>
            <button
              type="button"
              onClick={this.props.handleSharePopup}
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div className="form-gorup col-12 text-center">
              <FormLabel className="control-label">Copy Link</FormLabel>
              <FormControl
                as="textarea"
                value={this.state.shareUrl}
                className="form-control mb-3"
              />
            </div>
            <div>{element}</div>
          </div>
        </form>
      </div>
    );
  }
}
export const Alert_Message_To_User = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div
        class="container"
        style={{ width: "auto", height: "auto", textAlign: "center" }}
      >
        {props.showServicesPopupMessage ? (
          <>
            <div
              style={{ textAlign: "center", margin: "auto", marginTop: "10px" }}
            >
              <i className="fa" style={{ fontSize: "60px", color: "#6697ed" }}>
                <span className="fas fa-tools"></span>
              </i>
            </div>
            <h3> {props.alert_message}</h3>
            <br />
            <a href={"https://startupfuel.com/pages/all-services"}>
              <Card>
                <Card.Body style={{ paddingTop: "40px", margin: "0" }}>
                  <div className="title" style={{ color: "cornflowerblue" }}>
                    <Card.Title>
                      <b>Check it out</b>
                    </Card.Title>
                  </div>
                  <div>
                    <Card.Text
                      style={{ textAlign: "center", paddingTop: "5px" }}
                    >
                      <div style={{ textAlign: "center" }}></div>
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </a>
          </>
        ) : (
          <>
            <div
              class="checkmark"
              style={{ textAlign: "center", margin: "auto" }}
            ></div>
            <h2> {props.alert_message}</h2>
          </>
        )}

        <br />
      </div>
    </form>
  </div>
);

export const Checkmark_Message_To_User = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div
        class="container"
        style={{ width: "auto", height: "auto", textAlign: "center" }}
      >
        <div
          class="checkmark"
          style={{ textAlign: "center", margin: "auto" }}
        ></div>
        <h2> {props.alert_message}</h2>
        <br />
      </div>
    </form>
  </div>
);

export const Success_Popup = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal2"
  >
    <form>
      <div
        class="container"
        style={{
          width: "30%",
          height: "auto",
          textAlign: "center",
          background: "white",
        }}
      >
        <h2 style={{ color: "green" }}> {props.alert_message}</h2>
        <br />
      </div>
    </form>
  </div>
);

export const Report_Company_Flagging_0 = (props) => (
  <div className={`__popup __flag-modal ${props.show ? "__open" : ""}`}>
    {/* { console.log("handleRejectionEmail",props)} */}
    <div className="__blur" onClick={props.dismiss}></div>
    <div className="__popup-card">
      <div className="__popup-title"> {props.popup_header} </div>
      <div className="__popup-body"> {props.popup_body}</div>
      <div className="email-rejection-btn mt-2">
        <div>
          <FormLabel className="control-label">Email Subject</FormLabel>
          <FormControl
            type="text"
            value={props.headerRejection}
            placeholder="Ex.How did you hear about this opportunity?"
            name="header"
            onChange={props.handleRejectionEmail}
            className="form-control mb-3"
          />
        </div>
        <div>
          <FormLabel className="control-label">Email Body</FormLabel>
          <FormControl
            as="textarea"
            rows="5"
            value={props.bodyRejection}
            placeholder="Ex. Enter the name of the person that reffered you?"
            name="body"
            onChange={props.handleRejectionEmail}
          />
        </div>
      </div>
      <div className="__popup-footer">
        <div


            onClick={(event) => props.create_flagged(event, props.company_id, true)}
            className=""
          >

            Delete
          </div>
        
          
          <div onClick={props.dismiss} className="__danger">
            Cancel
          </div>
          <div className="bg-transparent">|</div>
          <div
            onClick={(event) => props.create_flagged(event, props.company_id)}
            className="__success"
          >

            Send Email & Delete
          </div>

      </div>
    </div>
  </div>
);

export const Add_To_Community_0 = (props) => (
  <div className={`__popup __flag-modal ${props.show ? "__open" : ""}`}>
    <div className="__blur" onClick={props.dismiss}></div>
    <div className="__popup-card">
      <div className="__popup-title"> {props.popup_header} </div>
      <div className="__popup-body">
        {" "}
        {props.popup_body} So, do you want to add Service Provider in your
        community?{" "}
      </div>
      <div className="__popup-footer">
        <div
          onClick={(event) =>
            props.add_company_community(event, props.company_id)
          }
          className="__success"
        >
          Yes
        </div>
        <div onClick={props.dismiss} className="__grey">
          Cancel
        </div>
      </div>
    </div>
  </div>
);

export const Development_Message_To_User = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div
        class="container"
        style={{ width: "auto", height: "auto", textAlign: "center" }}
      >
        <div
          class="checkmark"
          style={{ textAlign: "center", margin: "auto" }}
        ></div>
        <h2> {props.popup_header} </h2>
        <br />
        <h2> {props.popup_body} </h2>
      </div>
    </form>
  </div>
);

export const Community_Register_Login = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div
        class="container"
        style={{ width: "auto", height: "auto", textAlign: "center" }}
      >
        <div
          class="checkmark"
          style={{ textAlign: "center", margin: "auto" }}
        ></div>
        <h2> {props.popup_header} </h2>
        <br />
        <h2> {props.popup_body} </h2>
      </div>
    </form>
  </div>
);

export const Generic_Success_Message = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div
        class="container"
        style={{ width: "auto", height: "auto", textAlign: "center" }}
      >
        <div
          class="checkmark"
          style={{ textAlign: "center", margin: "auto" }}
        ></div>
        <h2> {props.popup_header} </h2>
        <br />
        <h2> {props.popup_body} </h2>
      </div>
    </form>
  </div>
);
export const Development_Question_Form_0 = (props) => (
  <div
    style={props.show === true ? { display: "block" } : { display: "none" }}
    onClick={props.dismiss}
    class="modal1"
  >
    <form class="modal-content animate">
      <div
        class="container"
        style={{ width: "auto", height: "auto", textAlign: "center" }}
      >
        <CardMedia
          image={developmentPic}
          title="Rocket"
          className={props.classes.developmentMedia}
        />
        {props.needs_help_with === "website" && (
          <div>
            <h1> Need help with web development? </h1>
            <br />
            <h3>
              {" "}
              We can help! Whether you need an e-commerce platform, web
              application, or a company website, we have specialists that know
              what it takes to create a fantastic web prescense that generates
              sales.{" "}
            </h3>
            <br />
          </div>
        )}
        {props.needs_help_with === "iOS App" && (
          <div>
            <h1> Need help with building an iOS app? </h1>
            <br />
            <h3>
              {" "}
              We can help! We have mobile app development specialists with years
              of experience at an affordable price. Whether you want to build
              the next uber, instagram, or tinder, etc, we can help!{" "}
            </h3>
            <br />
          </div>
        )}
        {props.needs_help_with === "android app" && (
          <div>
            <h1> Need help with building an android app? </h1>
            <br />
            <h3>
              {" "}
              We can help! We have mobile app development specialists with years
              of experience at an affordable price. Whether you want to build
              the next uber, instagram, or tinder, etc, we can help!{" "}
            </h3>
            <br />
          </div>
        )}
        <button
          className="btn btn-primary"
          type="submit"
          onClick={props.requestedHelp}
        >
          Request Support
        </button>
        <button type="button" class="cancelbtn" onClick={props.dismiss}>
          Cancel
        </button>
      </div>
    </form>
  </div>
);

export class Forgot_Password_0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={
          this.props.showForgotPass && !this.props.isLoggedIn
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => this.props.dismissModal(e, "showForgotPass")}
        class="modal1"
      >
        <form class="modal-content forgot-modal animate">
          <div class="container" style={{ padding: "30px" }}>
            <FormLabel>Email</FormLabel>
            <FormControl
              type="email"
              value={this.props.email}
              placeholder="User Email"
              name="email"
              onChange={this.props.handleChange}
              className="form-control mb-3 block-example border"
            />

            <div className="my-3">
              <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
                onClick={this.props.reset_pass_email}
              >
                Reset Password
              </button>
            </div>
            {this.props.forgot_pass_msg.length > 0 && (
              <p>{this.props.forgot_pass_msg}</p>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export class Invite_To_Community extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_copy: false,
    };
  }

  render() {
    return (
      <div
        style={
          this.props.show_invite ? { display: "block" } : { display: "none" }
        }
        onClick={(event) => this.props.dismissInvite(event)}
        className="modal1"
      >
        <form class="modal-content animate">
          <div class="container" style={{ padding: "30px" }}>
            <FormLabel style={{ width: "100%", textAlign: "center" }}>
              Enter the emails of the companies you would like to invite.{" "}
            </FormLabel>
            <FormControl
              type="email"
              value={this.props.emails}
              placeholder="Add a comma after each email. Ex john@gmail.com, jane@gmail.com"
              name="emails"
              onChange={(event) => this.props.handleEmailChange(event)}
              className="form-control mb-3 block-example border"
            />

            <div className="my-3">
              <button
                className="btn btn-primary btn-lg btn-block"
                style={{ width: "50%", margin: "auto" }}
                type="submit"
                onClick={this.props.handleEmailProcessing}
              >
                Send Invite Email
              </button>
            </div>

            <hr style={{ border: "1px solid #0001ff", width: "100%" }} />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Anyone with this link can join your community</th>

                  <th>Share</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="tableShareCom"
                    style={{ width: "80%", wordBreak: "break-word" }}
                  >
                    {this.props.url.href}&user_id={this.props.user_id}
                  </td>
                  <td style={{ width: "20%" }}>
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState({ show_copy: true });
                        navigator.clipboard.writeText(
                          `${this.props.url.href}&user_id=${this.props.user_id}`
                        );
                      }}
                    >
                      Copy Link
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {this.state.show_copy === true ? (
              <p style={{ color: "green" }}>Successfully Copied! </p>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export const Report_Company_Flagging = withContext(
  withStyles(styles)(Report_Company_Flagging_0),
  AppContext
);
export const Add_To_Community = withContext(
  withStyles(styles)(Add_To_Community_0),
  AppContext
);
export const Stay_In_Touch = withContext(
  withStyles(styles)(Stay_In_Touch_Comp),
  AppContext
);
export const Opportunity_Company_Update = withContext(
  withStyles(styles)(Opportunity_Company_Update1),
  AppContext
);
export const Register = withContext(Register_Comp, AppContext);
export const Forgot_Password = withContext(Forgot_Password_0, AppContext);
export const Sign_In = withContext(Sign_In_Comp, AppContext);
export const Development_Question_Form = withStyles(styles)(
  Development_Question_Form_0
);
