import React from 'react';
import StarRatings from 'react-star-ratings';

const Rating = ({ title, rating, changeRating, name, className }) => (
    <div className={`__rating`}>
        <div>{title}</div>
        <div className='__graphical-reviews'>
            <StarRatings
                rating={rating}
                starRatedColor="#C49733"
                numberOfStars={10}
                starDimension={20}
                starSpacing="0"
                changeRating={changeRating}
                name={name}
            />
            {!name && <span>{rating.toFixed(1)}</span>}
        </div>
    </div>
)

export default Rating;