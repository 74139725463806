/* (2019-06-11) - The card used in the SIR/application room in the flagged
submissions page. Allows you to delete and resore companies.
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import DeleteIcon from "@material-ui/icons/Delete";
import WebsiteIcon from "@material-ui/icons/Cloud";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import Button from "@material-ui/core/Button";
import AttachIcon from "@material-ui/icons/AttachFile";
import Moment from "moment";
import { Explore_Share_Popup } from "./popups";
import { SocialIcon } from "react-social-icons";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginLeft: theme.spacing.unit * 1.3,
    marginTop: "16px",
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  CardHeight: {
    overflow: "hidden",
    padding: "16px 5px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <Card className={`${classes.Card} ${classes.grow} ${classes.root}`}>
        <Explore_Share_Popup
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        <CardHeader
          avatar={
            <Avatar
              aria-label="Recipe"
              src={this.props.getAvatarUrl(
                this.props.company_files,
                "company avatar"
              )}
              className={classes.avatar}
            />
          }
          title={
            <a
              onClick={(event) =>
                this.props.previewSelectedCompany(event, this.props._id)
              }
            >
              <Typography variant="h6" className={classes.title} gutterBottom>
                {this.props.company_name}
              </Typography>
            </a>
          }
          subheader={"#" + this.props.company_type}
          className={classes.header}
          action={
            <IconButton aria-label="Share">
              <a
                onClick={(event) =>
                  this.props.handleDelete(event, this.props._id)
                }
              >
                <DeleteIcon />
              </a>
            </IconButton>
          }
        />
        {this.props.background_video_link !== undefined &&
        this.props.background_video_link !== null &&
        this.props.background_video_link.length > 0 ? (
          <div className="exploreCardMedia">
            <iframe
              style={{
                margin: 0,
                position: "absolute",
                top: "0",
                height: "100%",
                width: "100%",
              }}
              src={this.props.background_video_link}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        ) : (
          <CardActionArea href={"/profile?company_id=" + this.props._id}>
            <CardMedia
              className="exploreCardMedia"
              image={this.props.getAvatarUrl(
                this.props.company_files,
                "company background",
                this.props.temp_background_url
              )}
              title="background_picture"
            />
          </CardActionArea>
        )}
        <CardContent className={classes.CardHeight}>
          <Grid container direction="row">
            <Grid
              container
              direction="column"
              xs={4}
              sm={4}
              alignItems="center"
            >
              <Typography variant="caption" gutterBottom>
                Industry
              </Typography>
              {this.props.industry.slice(0, 3).map((val) => (
                <Chip
                  size="small"
                  //icon={<FaceIcon />}
                  label={val == "Artificial Intelligence" ? "A.I" : val}
                  //onClick={handleClick}
                  className={classes.chip}
                />
              ))}
            </Grid>
            <Grid
              container
              direction="column"
              xs={4}
              sm={4}
              alignItems="center"
            >
              <Typography variant="caption" gutterBottom>
                Stage
              </Typography>

              <Chip
                size="small"
                //icon={<FaceIcon />}
                label={this.props.getStage(this.props.stage, stage_list)}
                //onClick={handleClick}
                className={classes.chip}
              />
            </Grid>
            <Grid
              container
              direction="column"
              xs={4}
              sm={4}
              alignItems="center"
            >
              <Typography variant="caption" gutterBottom>
                Location
              </Typography>
              <Chip
                size="small"
                //icon={<FaceIcon />}
                label={this.props.country}
                //onClick={handleClick}
                className={classes.chip}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" style={{ marginTop: "10px" }}>
            {this.props.company_website !== undefined &&
              this.props.company_website.length > 0 && (
                <Grid
                  container
                  direction="column"
                  xs={4}
                  sm={4}
                  alignItems="center"
                >
                  <Typography variant="caption" gutterBottom>
                    Website
                  </Typography>
                  {this.props.company_website.includes("http") ||
                  this.props.company_website.includes("https") ? (
                    <a target="_blank" href={this.props.company_website}>
                      <WebsiteIcon />
                    </a>
                  ) : (
                    <a
                      target="_blank"
                      href={`http://${this.props.company_website}`}
                    >
                      <WebsiteIcon />
                    </a>
                  )}
                </Grid>
              )}
            {this.props.getFileUrl(
              this.props.company_files,
              "company pitchdeck"
            ) !== undefined &&
              this.props.getFileUrl(
                this.props.company_files,
                "company pitchdeck"
              ) !== 0 && (
                <Grid
                  container
                  direction="column"
                  xs={4}
                  sm={4}
                  alignItems="center"
                >
                  <Typography variant="caption" gutterBottom>
                    Pitch Deck
                  </Typography>
                  <a
                    href={this.props.getFileUrl(
                      this.props.company_files,
                      "company pitchdeck"
                    )}
                  >
                    <AttachIcon />
                  </a>
                </Grid>
              )}

            {this.props.linkedIn !== undefined &&
              this.props.linkedIn.length > 0 && (
                <Grid
                  container
                  direction="column"
                  xs={4}
                  sm={4}
                  alignItems="center"
                >
                  <Typography variant="caption" gutterBottom>
                    LinkedIn
                  </Typography>
                  <SocialIcon
                    style={{ margin: "3px", width: "30px", height: "30px" }}
                    target="_blank"
                    url={this.props.linkedIn}
                  />
                </Grid>
              )}
          </Grid>
        </CardContent>
        <Grid className={classes.footer}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className="col-3"
          >
            <CardActions className={classes.actions}>
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded,
                })}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="Show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div>
              <p
                style={{
                  fontSize: "12px",
                  margin: "unset",
                  fontStyle: "oblique",
                }}
              >
                {this.props.fintech_sub_vertical}
              </p>
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            className="col-4"
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={(event) =>
                this.props.handleRestore(event, this.props._id)
              }
              className={classes.button}
              size="small"
            >
              Restore
            </Button>
          </Grid>
        </Grid>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Description
                </Typography>
                <Typography paragraph>{this.props.short_bio}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Objective
                </Typography>
                <Typography paragraph>{this.props.objective}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Problem
                </Typography>
                <Typography paragraph>{this.props.problem}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Solution
                </Typography>
                <Typography paragraph>{this.props.solution}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Target Market
                </Typography>
                <Typography paragraph>{this.props.target_market}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Market Size
                </Typography>
                <Typography paragraph>{this.props.market_size}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Competitors
                </Typography>
                <Typography paragraph>{this.props.competitors}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Revenue Model
                </Typography>
                <Typography paragraph>{this.props.revenue_model}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Revenue Since Inception
                </Typography>
                <Typography paragraph>
                  {this.props.revenue_since_inception}
                </Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Growth
                </Typography>
                <Typography paragraph>{this.props.growth}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Traction
                </Typography>
                <Typography paragraph>{this.props.traction}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Market Size
                </Typography>
                <Typography paragraph>
                  {this.props.marketing_strategy}
                </Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Team Description
                </Typography>
                <Typography paragraph>{this.props.team_description}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Incubator & Accelerator History
                </Typography>
                <Typography paragraph>
                  {this.props.incubator_accelerator_history}
                </Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Fundraising
                </Typography>
                <Typography paragraph>{this.props.raising_funds}</Typography>
              </div>
              <div style={{ width: "inherit" }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                  paragraph
                >
                  Contact Info
                </Typography>
                <p>
                  <span style={{ fontStyle: "italic" }}>Name: </span>{" "}
                  {this.props.company_owner.first_name}{" "}
                  {this.props.company_owner.last_name}{" "}
                </p>
                <p>
                  <span style={{ fontStyle: "italic" }}>Email: </span>{" "}
                  {this.props.company_owner.email}{" "}
                </p>
                <p>
                  <span style={{ fontStyle: "italic" }}>#: </span>{" "}
                  {this.props.contact_phone}{" "}
                </p>
              </div>
            </Grid>
          </CardContent>
          <Grid className={classes.footer}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              className="col-3"
            >
              <CardActions className={classes.actions}>
                <IconButton
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <div>
                <p
                  style={{
                    fontSize: "12px",
                    margin: "unset",
                    fontStyle: "oblique",
                  }}
                >
                  {this.props.fintech_sub_vertical}
                </p>
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              className="col-4"
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "green", color: "white" }}
                onClick={(event) =>
                  this.props.handleRestore(event, this.props._id)
                }
                className={classes.button}
                size="small"
              >
                Restore
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
