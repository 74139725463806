/* (2019-06-10) - Contains the content for the opportunity:
- DESCRIPTION
- RELEVANT TAGS FOR THE APPLICANTS
- VENUE
-PRIZE / PRICE
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { stage_list, distinction_list } from "../../boilerplate/list.json";
import "./OppBody.css";
import Moment from "moment";
import { FaRegCalendarAlt, FaHandHoldingUsd } from "react-icons/fa";
import default_pic from "../../img/default_company_pic.png";
import {borderRadius} from "@mui/system";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e0e0e0",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  table: {
    backgroundColor: "#f5f5f5",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  divider: {
    width: 2,
    height: "100%",
    margin: 10,
  },
  columnOne: {
    paddingLeft: 20,
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

let id = 0;

function createData(name, item) {
  id += 1;
  return {
    id,
    name,
    item,
  };
}

const rows = [
  createData("price", "$1,000,000"),
  createData("Prize", "$1,000,000"),
  createData("other things", "Other things that need to be typed out... IDK"),
];

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper square className="OppBodyUBaid bg-transparent">
        <Grid

          container
          direction="row"
          justify="flex-start"
        >
          <Grid container direction="row" item xs={12} sm={8}>
            <Grid container direction="column">
              {/* <Typography variant="h6" className={classes.center} gutterBottom>
                Overview
              </Typography> */}

              <Table >
                <TableHead>
                  <TableRow>
                    {/* <CustomTableCell> */}
                      <div className="Description-border oppo-border-row">
                        <hr />
                        <h4>Description</h4>
                      </div>
                    {/* </CustomTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody className="body-padding-wrpaer">
                  <Typography
                      className="mobile-padding"
                      style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", paddingTop: "10px"}}
                  >
                    <div style={{ whiteSpace: "pre-line" }}>
                      {this.props.opportunity_description}
                    </div>
                    
                    
                  </Typography>
                </TableBody>
              </Table>
              {this.props.objective !== null &&
                this.props.objective !== undefined &&
                this.props.objective.length > 0 && (
                  <Table id="objective" ref={this.props.objectiveRef}>
                    <TableHead>
                      <TableRow>
                        {/* <CustomTableCell> */}
                        <div className="Description-border oppo-border-row">
                            <hr />
                            <h4>OBJECTIVE</h4>
                          </div>
                          {/* </CustomTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className="body-padding-wrpaer">
                      <Typography className={classes.root} gutterBottom>
                        <div style={{ whiteSpace: "pre-line" }}>
                          {this.props.objective}
                        </div>
                      </Typography>
                    </TableBody>
                  </Table>
                )}


                
              {this.props.prize !== null &&
                this.props.prize !== undefined &&
                this.props.prize !== "" && (
                  <Table id="Prize" ref={this.props.prizeRef}>
                    <TableHead>
                      <TableRow>
                        {/* <CustomTableCell> */}
                          <div className="Description-border oppo-border-row">
                            <hr />
                            <h4>{this.props.opportunity_type === 'Pitch Competitions' ? 'Prizes/Benefits' : 'Investment/Resources'}</h4>
                          </div>
                        {/* </CustomTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className="body-padding-wrpaer">
                      <Typography className="mobile-padding" style={{paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", paddingTop: "10px"}}>
                        <div style={{ whiteSpace: "pre-line" }}>
                          {this.props.prize}
                        </div>
                      </Typography>
                    </TableBody>
                  </Table>
                )}
              {/* (this.props.ticket_price !== null && this.props.ticket_price !== undefined && this.props.ticket_price !== 0) &&
                <Table className={ classes.table }>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell>
                        Ticket Price
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Typography
                      className={ classes.root }
                      gutterBottom>
                      $
                      { this.props.ticket_price }
                    </Typography>
                  </TableBody>
                </Table> */}
              {this.props.amount !== null && this.props.amount !== undefined && (
                <Table id="Amount" ref={this.props.amountRef}>
                  <TableHead>
                    <TableRow>
                      {/* <CustomTableCell> */}
                        <div className="Description-border oppo-border-row">
                          <hr />
                          <h4>Amount</h4>
                        </div>
                        {/* </CustomTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody className="body-padding-wrpaer">
                    <Typography className={classes.root} gutterBottom>
                      ${this.props.amount}
                    </Typography>
                  </TableBody>
                </Table>
              )}
              {this.props.prize_pitch !== null &&
                this.props.prize_pitch !== undefined && (
                  <Table id="Pitch_Prize" ref={this.props.pitchPrize}>
                    <TableHead>
                      <TableRow>
                        {/* <CustomTableCell> */}
                          <div className="Description-border oppo-border-row">
                            <hr />
                            <h4>Pitch Prize</h4>
                          </div>
                          {/* </CustomTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className="body-padding-wrpaer">
                      <Typography className={classes.root} gutterBottom>
                        <div style={{ whiteSpace: "pre-line" }}>
                          {this.props.prize_pitch}
                        </div>
                      </Typography>
                    </TableBody>
                  </Table>
                )}

              {this.props.show_judge_on_opportunity !== undefined &&
                this.props.show_judge_on_opportunity === true &&
                this.props.judges !== undefined &&
                this.props.judges !== null &&
                this.props.judges.length > 0 && (
                  <Table id="Team" ref={this.props.teamref}>
                    <TableHead>
                      <TableRow>
                        {/* <CustomTableCell> */}
                        <div className="Description-border oppo-border-row">
                            <hr />
                            <h4>Team</h4>
                          </div>
                        {/* </CustomTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className="body-padding-wrpaer">
                      <Typography className={classes.root}>
                        <div className="card-body row">
                          {this.props.judges.map((judge) => (
                            <div style={{ margin: "10px" }}>
                              <div
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    this.props.getAvatarUrl(
                                      judge.files,
                                      "user avatar"
                                    ) +
                                    ")",
                                  backgroundSize: "cover",
                                  width: "48px",
                                  height: "48px",
                                  borderRadius: "26px",
                                  margin: "auto",
                                }}
                              ></div>
                              <p>
                                {judge.first_name} {judge.last_name}
                              </p>
                            </div>
                          ))}
                        </div>
                      </Typography>
                    </TableBody>
                  </Table>
                )}
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={4}
          >


            <Table>
              <TableBody>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  marginTop: "25px"
                }}>
                  <div className="profile-img-text-wraper">
                    <img style={{borderRadius: '10px'}} height={150} width={150} alt={"logo"}
                         src={this.props.affiliated_company.company_files !== undefined
                             ? this.props.getAvatarUrl(
                                 this.props.affiliated_company.company_files,
                                 "company avatar"
                             )
                             : default_pic}/>
                  </div>
                  <div>
                    <p className="type-oppo-new mt-4">{this.props.opportunity_type}</p></div>
                  <div className="oppo-location-wraper">

                    <p>
                      <a
                          href={`/profile?company_id=${this.props.affiliated_company._id}`}
                      >
                        {this.props.affiliated_company.company_name}{" "}
                      </a>
                    </p>
                  </div>
                  <div>
                    <p style={{color: "#DA1C24", marginBottom: 0}}>
                      <svg style={{marginRight:"4px", marginBottom:"-2px"}} width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.36666 11.1667L0.666656 10.4229L8.96666 1.60419H4.06666V0.541687H10.6667V7.55419H9.66666V2.34794L1.36666 11.1667Z"
                            fill="#DA1C24"/>
                      </svg>
                      {this.props.affiliated_company.company_website !== undefined &&
                          <a style={{color: "#DA1C24"}} href={this.props.affiliated_company.company_website.startsWith('http') ? this.props.affiliated_company.company_website : `http://${this.props.affiliated_company.company_website}`}
                             target="_blank" rel="noopener noreferrer">
                            {this.props.affiliated_company.company_website}
                          </a>}
                    </p>
                    <p>
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.96874 16.2563V14.9281C6.35138 14.7629 5.00555 14.1431 3.93124 13.0688C2.85694 11.9945 2.23715 10.6486 2.07187 9.03127H0.743744V7.96877H2.07187C2.23715 6.35141 2.85694 5.00558 3.93124 3.93127C5.00555 2.85697 6.35138 2.23718 7.96874 2.0719V0.743774H9.03124V2.0719C10.6486 2.23718 11.9944 2.85697 13.0687 3.93127C14.143 5.00558 14.7628 6.35141 14.9281 7.96877H16.2562V9.03127H14.9281C14.7628 10.6486 14.143 11.9945 13.0687 13.0688C11.9944 14.1431 10.6486 14.7629 9.03124 14.9281V16.2563H7.96874ZM8.49999 13.8834C9.97569 13.8834 11.2418 13.3551 12.2984 12.2985C13.355 11.2419 13.8833 9.97572 13.8833 8.50002C13.8833 7.02433 13.355 5.75818 12.2984 4.70159C11.2418 3.64499 9.97569 3.11669 8.49999 3.11669C7.0243 3.11669 5.75815 3.64499 4.70156 4.70159C3.64496 5.75818 3.11666 7.02433 3.11666 8.50002C3.11666 9.97572 3.64496 11.2419 4.70156 12.2985C5.75815 13.3551 7.0243 13.8834 8.49999 13.8834ZM8.49999 11.1563C7.75624 11.1563 7.1276 10.8995 6.61406 10.386C6.10051 9.87242 5.84374 9.24377 5.84374 8.50002C5.84374 7.75627 6.10051 7.12763 6.61406 6.61409C7.1276 6.10054 7.75624 5.84377 8.49999 5.84377C9.24374 5.84377 9.87239 6.10054 10.3859 6.61409C10.8995 7.12763 11.1562 7.75627 11.1562 8.50002C11.1562 9.24377 10.8995 9.87242 10.3859 10.386C9.87239 10.8995 9.24374 11.1563 8.49999 11.1563ZM8.49999 10.0938C8.9486 10.0938 9.32638 9.9403 9.63333 9.63336C9.94027 9.32641 10.0937 8.94863 10.0937 8.50002C10.0937 8.05141 9.94027 7.67363 9.63333 7.36669C9.32638 7.05975 8.9486 6.90627 8.49999 6.90627C8.05138 6.90627 7.6736 7.05975 7.36666 7.36669C7.05972 7.67363 6.90624 8.05141 6.90624 8.50002C6.90624 8.94863 7.05972 9.32641 7.36666 9.63336C7.6736 9.9403 8.05138 10.0938 8.49999 10.0938Z"
                          fill="#5A6473"/>
                    </svg>

                    {this.props.affiliated_company.city !== undefined &&
                        this.props.affiliated_company.city + ", "}
                    {this.props.affiliated_company.country !== undefined &&
                        this.props.affiliated_company.country}
                  </p>
                  </div>

                </div>


              </TableBody>
            </Table>

            <Table id="key_dates" ref={this.props.venueLocationRef}>
              <TableRow>
                {/* <CustomTableCell> */}
                <div className="Description-border oppo-border-row">
                  <hr/>
                  <h4>Key Dates</h4>
                </div>
                {/* </CustomTableCell> */}
              </TableRow>
              <TableBody>
                <Typography>
                  <div className="company-start-close typography-keydates">

                    <p className="Applications-open">Applications open:</p>
                    <p className="Applications-open-date">{Moment(this.props.start_intake_date).format('LL')}</p>
                    <p className="Applications-open-close">Applications close:</p>
                    <p className="Applications-open-close-date"> {Moment(this.props.end_intake_date).format('LL')}</p>
                  </div>
                </Typography>
              </TableBody>
            </Table>

            <Table>
              <TableHead>
                <TableRow>
                  {/* <CustomTableCell> */}
                  <div className="Description-border oppo-border-row">
                    <hr/>
                    <h4>Criteria</h4>
                  </div>
                  {/* </CustomTableCell> */}
                </TableRow>
              </TableHead>
              <TableRow>
              <TableBody className="body-padding-wrpaer">
                <div className="right-side-oppo-body">
                  <p id="industry-subtitle">Industry</p>
                </div>
                <div style={{paddingBottom: "20px"}} className="industry-padding-wrpaer ">
                  {this.props.target_industries.map((industry) => (
                      <Chip
                          size="small"
                          // icon={<FaceIcon />}
                          label={industry}
                          //onClick={handleClick}
                          className={classes.chip}
                      />
                  ))}
                </div>
              </TableBody>
              </TableRow>
              <TableRow>
                <TableBody className="body-padding-wrpaer">
                  <div className="right-side-oppo-body">
                    <p id="industry-subtitle">Company Stage</p>
                  </div>
                  <div style={{paddingBottom: "20px"}} className="industry-padding-wrpaer ">
                    {this.props.target_stage_companies.map((stage_name) => (
                        <Chip
                            size="small"
                            // icon={<FaceIcon />}
                            label={this.props.getStage(stage_name, stage_list)}
                            //onClick={handleClick}
                            className={classes.chip}
                        />
                    ))}
                  </div>
                </TableBody>
              </TableRow>
              <TableRow>
                <TableBody className="body-padding-wrpaer">
                  <div className="right-side-oppo-body">
                    <p id="industry-subtitle">Countries Accepted</p>
                  </div>
                  <div style={{paddingBottom: "20px"}} className="industry-padding-wrpaer ">
                    {this.props.geographical_location.map((location) => (
                        <Chip
                            size="small"
                            // icon={<FaceIcon />}
                            label={location}
                            //onClick={handleClick}
                            className={classes.chip}
                        />
                    ))}
                  </div>
                </TableBody>
              </TableRow>
              <TableRow>
                <TableBody className="body-padding-wrpaer">
                  <div className="right-side-oppo-body">
                    <p id="industry-subtitle">Target Business Models</p>
                  </div>
                  <div style={{paddingBottom: "20px"}} className="industry-padding-wrpaer ">
                    {this.props.target_model_companies.map((location) => (
                        <Chip
                            size="small"
                            // icon={<FaceIcon />}
                            label={location}
                            //onClick={handleClick}
                            className={classes.chip}
                        />
                    ))}
                  </div>
                </TableBody>
              </TableRow>
              {this.props.target_distinctions.length > 0 && !this.props.target_distinctions.includes('Not Applicable') && (
                  <TableRow>
                    <TableBody className="body-padding-wrpaer">
                      <div className="right-side-oppo-body">
                        <p id="industry-subtitle">Target Business Models</p>
                      </div>
                      <div style={{paddingBottom: "20px"}} className="industry-padding-wrpaer ">
                        {this.props.target_model_companies.map((location) => (
                            <Chip
                                size="small"
                                // icon={<FaceIcon />}
                                label={location}
                                //onClick={handleClick}
                                className={classes.chip}
                            />
                        ))}
                      </div>
                    </TableBody>
                  </TableRow>)}
                </Table>






            {this.props.target_distinctions.length > 0 && !this.props.target_distinctions.includes('Not Applicable') && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <div style={{marginTop:"20px", marginLeft:"8px"}} className="right-side-oppo-body body-padding-wrpaer">
                        <p>Founder Diversity</p>
                      </div>
                    </TableRow>
                  </TableHead>
                  <div style={{paddingBottom: "20px"}} className="right-side-oppo-body body-padding-wrpaer">
                    {this.props.target_distinctions.map((distinction) => (
                        <Chip
                            size="small"
                            label={this.props.getDiversity(
                                distinction,
                                distinction_list
                            )}
                            className={classes.chip}
                        />
                    ))}
                  </div>
                </Table>
            )}


          </Grid>
        </Grid>
      </Paper>
    );
  }
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
