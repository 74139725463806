/* (2019-06-11) - This is the mobile review card that appears when a user clicks
'review' on the /submissions page or on the /applicant_room_top page.
*/
import React, { Component, Fragment, createRef } from "react";
import StarRatings from "react-star-ratings";
import TextField from "@material-ui/core/TextField";
import Comment from "./../comment/Comment";
import "./ReviewCard.scss";
import Rating from "./Rating";
import { Button } from "@material-ui/core";
import URL from "url-parse";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { ComponentToPrint } from "./ComponetPrint";
import TearsheetPdf from "./TearsheetPdf";
import { saveAs } from "file-saver";
import Tooltip from "@mui/material/Tooltip";
// import { PhotoIcon } from "@heroicons/react/24/solid";
import Grid from "@material-ui/core/Grid";
import AttachIcon from "@material-ui/icons/AttachFile";
import { FormControl } from "react-bootstrap";
import update from "immutability-helper";

import {
  pdf,
  // PDFDownloadLink,
  // PDFViewer
} from "@react-pdf/renderer";
import { Tune } from "@material-ui/icons";

// import chatgpt from "../../../../../../node-chatgpt-api";

export default class ReviewsCard extends Component {
  constructor() {
    super();
    this.componentRef = createRef();

    this.state = {
      url: new URL(window.location.href, true),
      questions: [],
      apiquestions: [],
      apianswer: "",
      question: "",
      answer: "",
      editingQuestionId: null,
      selectedFile: null,
      pitchdeckDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      pitchdeckUrl: 0,
      UploadingDocument: false,
      isGenerating: false,
    };
    this.props = {
      totalOverall: 0,

      totalIdea: 0,
      totalTechnology: 0,
      totalMarketing: 0,
      totalTeam: 0,
      overallRating: 5,
      comment: "",
      feedback: "",
      reviews: [],
      oppotunitydata: {},
      disableButton: false,
      questinID: "",
      disableButton: false,
      answerGenetared: "",
      isSummarizing: false,
      isActiveeditField: "",
      answerValue: "",
      opportunity_formApi: [],
      FormDataOpper: {},
      questionTextNew: "",
      activeCompany: {},
      showFlagPopup: false,
      popup_body: " ",
      popup_header: " ",
      UpdateTearsheet: "",
    };
  }
  componentDidMount() {
    document.body.classList.add("no-scroll");
    console.log(this.state.url.query.opportunity_id);
    axios
      .get(
        `/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`
      )
      .then((response) => {
        console.log(response);
        this.setState({
          oppotunitydata: response.data.result,
        });
        console.log("this.state.opportunitydata");
        console.log(this.state.oppotunitydata);
      });
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  handlePrint = () => {
    if (this.componentRef.current) {
      this.componentRef.current.handlePrint();
    }
  };
  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  handleUploadClick = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      this.uploadPitchDeck(selectedFile);
    } else {
      alert("Please select a file to upload.");
    }
  };

  componentDidMount() {
    this.fetchQuestionApi();
    this.fetchapiopperForm();
    this.updatePitchdeckUrl();
  }

  updatePitchdeckUrl = () => {
    const pitchdeckFileUrl = this.getFileUrl(
      this.props.selected_company[0].company_files,
      "company pitchdeck"
    );
    console.log("pitchdeckFileUrl", pitchdeckFileUrl);
    this.setState({ pitchdeckUrl: pitchdeckFileUrl });
  };

  fetchapiopperForm = () => {
    axios
      .get(
        `get_id_company_from_opportunity?companyId=${this.props.selected_company[0]?.applicant_company}`
      )
      .then((response) => {
        console.log("response 66 5", response);

        this.setState({
          FormDataOpper: response?.data,
        });
      });
  };

  fetchQuestionApi = () => {
    this.setState({ disableButton: true });
    axios.get(`/get_questions_all`).then((response) => {
      console.log("response 70", response);
      this.setState({
        apiquestions: response?.data?.questions,
        disableButton: false,
      });
    });
  };

  //   handleDeleteQuestion =(id)=>{

  //     axios.delete(`/questions/${id}`).then((response) => {
  //         if(response){
  //             this.fetchQuestionApi()
  //         }
  //       }).catch((error) => {
  //         console.error("Error deleting question:", error);
  //       });
  // }

  // handleSummarize = async (questionId, questionText) => {
  //   let query = 'Write an answer of question using summany.\n';
  //   if(!questionText){
  //     return false;
  //   }
  //   query += `Question: ${questionText}\n`;
  //   query += `Here is the Summary \n`
  //     // console.log("this.props.opportunity_data.opportunity_form",this.props.opportunity_data.opportunity_form)
  //   Object.keys(this.props.opportunity_data.opportunity_form).map((opp_form_key, i) => {
  //     if(this.props.opportunity_data.opportunity_form[opp_form_key]["display"] == true){
  //       query += `${this.props.opportunity_data.opportunity_form[opp_form_key]["title"]}:${this.props.original_company[opp_form_key]}, \n`;
  //     }
  //   });
  //   console.log("query 160",query)
  //   axios.post(`/fetch_summarize`,{
  //     question:query
  //   })
  //   .then((response) => {
  //     console.log("response 142,", response);
  //     const newAnswers = { ...this.state.apianswer };
  //     newAnswers[questionId] = response.data.result;
  //     this.setState({ apianswer: newAnswers });
  //   });

  //   // axios.post(`/fetch_summarize`, {
  //   //   question: questionText,
  //   // }).then((response) => {
  //   //   console.log("response 142,", response);
  //   //   const newAnswers = { ...this.state.apianswer };
  //   //   newAnswers[questionId] = response.data.result;
  //   //   this.setState({ apianswer: newAnswers });
  //   // });
  // };

  // handleSummarizeAll = async () => {
  //   this.setState({ isSummarizing: true }); // Disable the button during API call

  //   const newAnswers = { ...this.state.apianswer };
  //   const { apiquestions } = this.state;
  //   for (const question of apiquestions) {
  //     if (!newAnswers[question._id]) {
  //       let query = "Write an answer of question using summary.\n";
  //       if (!question.question) {
  //         continue;
  //       }
  //       query += `Question: ${question.question}\n`;
  //       query += `Here is the Summary \n`;
  //       console.log("query 160", query);
  //       Object.keys(this.props.opportunity_data.opportunity_form).forEach(
  //         (opp_form_key) => {
  //           if (
  //             this.props.opportunity_data.opportunity_form[opp_form_key][
  //               "display"
  //             ] === true
  //           ) {
  //             query += `${this.props.opportunity_data.opportunity_form[opp_form_key]["title"]}:${this.props.original_company[opp_form_key]}, \n`;
  //           }
  //         }
  //       );
  //       console.log("query 1450", query);
  //       const response = await axios.post(`/fetch_summarize`, {
  //         question: query,
  //       });
  //       console.log("response 185",response)
  //       newAnswers[question._id] = response.data.result;
  //     }
  //   }

  //   this.setState({ apianswer: newAnswers, isSummarizing: false });
  // };

  handleSummarizeAll = async () => {
    this.setState({ isSummarizing: true });
    const newAnswers = { ...this.state.apianswer };
    const { apiquestions } = this.state;
    for (const question of apiquestions) {
      if (!newAnswers[question._id]) {
        let query = "Write an answer of question using summary.\n";
        if (!question.question) {
          continue;
        }
        query += `Question: ${question.question}\n`;
        query += `Here is the Summary \n`;
        console.log("query 160", query);

        Object.keys(this.props.opportunity_data.opportunity_form).forEach(
          (opp_form_key) => {
            if (
              this.props.opportunity_data.opportunity_form[opp_form_key][
                "display"
              ] === true
            ) {
              query += `${this.props.opportunity_data.opportunity_form[opp_form_key]["title"]}:${this.props.original_company[opp_form_key]}, \n`;
            }
          }
        );
        console.log("query 1450", query);

        try {
          const response = await axios.post(`/fetch_summarize`, {
            question: query,
          });

          // Update state for the current question
          newAnswers[question._id] = response.data.result;
          this.setState({ apianswer: newAnswers });
        } catch (error) {
          console.error("Error fetching summary:", error);
        }
      }
    }

    this.setState({ isSummarizing: false });
  };

  // handleEditQuestion =(quetext,id)=>{
  //   this.setState({ question: quetext,editingQuestionId:true,questinID:id});

  // }

  // handleUpdateQuestion = (event) => {
  //   event.preventDefault();
  //   const {question,questinID} = this.state;
  //   axios.post(`/questions/${questinID}`, {
  //       question: question,
  //     }).then((response) => {
  //       this.fetchQuestionApi()
  //       this.setState({ question: "",editingQuestionId:false });
  //       console.log("edit response 142,", response);
  //     });

  // };

  // handleAddQuestion = (event) => {
  //   event.preventDefault();
  //   const { question } = this.state;
  //       console.log("question 240",question)
  //       if(question){
  //           axios.post(`/add_question_ai`,{
  //               question:question
  //             })
  //             .then((response) => {
  //                 console.log("response 245",response);
  //               if(response){
  //               this.setState({ question: "" });
  //               this.fetchQuestionApi()
  //               }
  //             });
  //       }
  // };

  getAvatarUrl = (array, file_type, temp_background_url) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar" || file_type === "user avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        if (
          temp_background_url !== undefined &&
          temp_background_url !== null &&
          temp_background_url.length > 0
        ) {
          return temp_background_url;
        } else {
          return default_background;
        }
      }
    }
  };

  // handleAddQuestion = (event) => {
  //   event.preventDefault();
  //   const { questions, question, answer } = this.state;
  //   if (!question) {
  //     return false;
  //   }
  //   const newQuestion = {
  //     id: questions.length + 1,
  //     question,
  //     answer,
  //   };
  //   this.setState({
  //     questions: [...questions, newQuestion],
  //     question: "",
  //     answer: "",
  //   });
  // };

  handleEditQuestion = (id) => {
    const { questions } = this.state;
    const question = questions.find((q) => q.id === id);
    this.setState({
      question: question.question,
      answer: question.answer,
      editingQuestionId: id,
    });
  };

  handleUpdateQuestion = (event) => {
    event.preventDefault();
    const { questions, question, answer, editingQuestionId } = this.state;
    if (!question) {
      return false;
    }
    const updatedQuestions = questions.map((q) =>
      q.id === editingQuestionId ? { ...q, question, answer } : q
    );
    this.setState({
      questions: updatedQuestions,
      question: "",
      answer: "",
      editingQuestionId: null,
    });
  };

  redirectToAddQuestion = () => {
    const { opportunity_id } = this.state.url.query;
    window.location.href = `/applicant_room_settings_add_question?opportunity_id=${opportunity_id}`;
  };

  handleDeleteQuestion = (id) => {
    const { questions } = this.state;
    const updatedQuestions = questions.filter((q) => q.id !== id);
    this.setState({
      questions: updatedQuestions,
    });
  };

  handleQuestionChange = (event) => {
    this.setState({ question: event.target.value });
  };

  // handleAnswerChange = (event) => {
  //   this.setState({ answer: event.target.value });
  // };

  handleAddQuestion = (newQuestion) => {
    const { questions } = this.state;
    this.setState({
      questions: [...questions, newQuestion],
    });
  };
  updateQuestionWithAnswer = (questionId, answer) => {
    this.setState((prevState) => ({
      questions: prevState.questions.map((question) => {
        if (question.id === questionId) {
          return { ...question, answer };
        }
        return question;
      }),
    }));
  };

  uploadPitchDeck = (file) => {
    // Basic validation to ensure a file is selected
    if (!file) {
      console.error("No file selected for upload.");
      return;
    }

    // Creating FormData to send the file
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", "pitchdeck");
    formData.append("file_type", "company pitchdeck");
    formData.append("object_ref", "company");
    formData.append(
      "object_ref_value",
      this.props.selected_company[0].applicant_company
    );

    // Configurations for the axios request
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    console.log("formData", formData);
    console.log(
      `Calling Upload pitchdeck api: /upload_file_secure?company_id=${this.props.selected_company[0].applicant_company}`
    );

    // Perform the file upload via axios
    axios
      .post(
        `/upload_file_secure?company_id=${this.props.selected_company[0].applicant_company}`,
        formData,
        config
      )
      .then((response) => {
        if (response.data.status === "success") {
          // Assuming fetchData, getAvatarUrl, and computeProfileCompleteness are methods that update your app's state or UI
          this.fetchData();
          window.location.reload();

          // Update the state to reflect the new pitchdeck info if similar file_type already exists
          const fileType = "company pitchdeck";
          const index = this.state.company_files.findIndex(
            (item) => item.file_type === fileType
          );
          if (index !== -1) {
            this.setState({
              company_files: this.state.company_files.map((item, idx) =>
                idx === index
                  ? { ...item, file_url: response.data.result.file_url }
                  : item
              ),
              showEditPopup: false,
            });
          } else {
            // If no existing pitchdeck, just add the new one
            this.setState({
              company_files: [
                ...this.state.company_files,
                {
                  file_type: fileType,
                  file_url: response.data.result.file_url,
                },
              ],
              showEditPopup: false,
            });
          }
        } else {
          console.error("Upload failed:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  getFileUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        console.log("file_url", new_arr[0].file_url);
        return new_arr[0].file_url;
      }
    } else {
      console.log(`No ${file_type} found`);
      return 0;
    }
  };

  handleFileUpload = (e) => {
    let file = e.target.files[0];
    let that = this;
    if (e.target.dataset.fileType === "pitchdeck" && this.props.isLoggedIn) {
      this.setState({
        pitchdeckDetails: {
          file,
          file_name: "pitchdeck",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.props.selected_company[0].applicant_company,
        },
      });
    } else if (
      e.target.dataset.fileType === "pitchdeck" &&
      !this.props.isLoggedIn
    ) {
      this.setState({
        pitchdeckDetails: {
          file,
          file_name: "pitchdeck",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
        },
      });
    }
  };

  handleFileSubmit = (type, company_id) => {
    this.setState({
      UploadingDocument: true,
    });
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state[type]) {
      formData.append(key, this.state[type][key]);
    }
    if (typeof this.state[type].file === "object") {
      axios
        .post(
          `/upload_file_secure?company_id=${this.props.selected_company[0].applicant_company}`,
          formData,
          config
        )
        .then(
          (response) => {
            if (response.data.status === "success") {
              //if (this.state.company_files.length > 0){

              this.setState({
                pitchdeckUrl: response.data.result.file_url,
                UploadingDocument: false,

                // [type]: {
                //   file: '',
                //   file_name: '',
                //   file_type: ''
                // }

                showEditPopup: false,
              });
              console.log(`Updated pitchdeck url: ${this.state.pitchdeckUrl}`);
            } else {
              this.setState({
                company_files: [
                  ...this.state.company_files,
                  ...[
                    {
                      file_type: response.data.result.file_type,
                      file_url: response.data.result.file_url,
                    },
                  ],
                ],

                showEditPopup: false,
              });
            }
          } // do else later
        )
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  handleSummarizeNew = async (event, questionId) => {
    event.preventDefault();
    let query = "Write an answer of question using summary.\n";
    const question = event.target.getAttribute("data-question");
    if (!question) {
      return false;
    }
    this.handleAddQuestion({ id: questionId, question });
    this.setState({ question: "" });
    query += `Question: ${question}\n`;
    query += `Here is the Summary \n`;

    axios
      .post(`/fetch_summarize`, {
        question: query,
      })
      .then((response) => {
        // Update the state to save the answer with the corresponding question ID
        this.updateQuestionWithAnswer(questionId, response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching summary:", error);
      });
  };

  // handleSummarizeNew = async (event) => {
  //   let query = "Write an answer of question using summany.\n";
  //   const question = event.target.getAttribute("data-question");
  //   if (!question) {
  //     return false;
  //   }

  //   query += `Question: ${question}\n`;
  //   query += `Here is the Summary \n`;
  //   console.log("query 155", query);
  //   Object.keys(this.props.opportunity_data.opportunity_form).map(
  //     (opp_form_key, i) => {
  //       if (
  //         this.props.opportunity_data.opportunity_form[opp_form_key][
  //         "display"
  //         ] == true
  //       ) {
  //         query += `${this.props.opportunity_data.opportunity_form[opp_form_key]["title"]}:${this.props.original_company[opp_form_key]}, \n`;
  //       }
  //     }
  //   );
  //   console.log("query 160", query);
  //   axios
  //     .post(`/fetch_summarize`, {
  //       question: query,
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       this.setState({ answer: response.data.result });
  //     });
  // };

  handleDealInfo = (id) => {
    console.log("id", id);
    this.setState({ isActiveeditField: id });
  };

  handleAnsDeal = (e, id) => {
    let value = e.target.value;
    this.setState({ questionTextNew: value });
    if (value) {
      let obj = {
        ...this.props.opportunity_data.opportunity_form[id],
        ["helper_text"]: value,
      };
      this.setState({
        opportunity_formApi: {
          ...this.props.opportunity_data.opportunity_form,
          [id]: obj,
        },
      });
    }
  };

  handleFormSave = (fieldName) => {
    const { questionTextNew } = this.state;
    console.log("questionTextNew", questionTextNew);
    if (questionTextNew) {
      axios.put(`/edit_company_from_opportunity`, {
        helper_text: questionTextNew,
        companyId: this.props.selected_company[0].applicant_company,
        fieldName: fieldName,
      });
      this.fetchapiopperForm();
    }
    this.fetchapiopperForm();
    setTimeout(() => {
      this.setState({ isActiveeditField: null });
    }, 1000);
    // this.props.handleHide()
  };
  handleButtonClick = () => {
    // Call the handleSubmit function in the child component
    setTimeout(() => {
      this.componentRef.handleSubmit();
    }, 100);
  };
  handleClickGenerate = () => {
    this.setState({ isGenerating: true });
    console.log("Generating...")

    setTimeout(() => {
      this.componentRef.handleGenerate();

      this.setState({ isGenerating: false });
      console.log("Set isgenerating to false")
    }, 100);
  };
  setUpdateTearsheet = (value) => {
    this.setState({ UpdateTearsheet: value, refState: false });
  };

  render() {
    const hasReviewed = this.props.current_judge_has_reviewed;
    const {
      questions,
      question,
      answer,
      editingQuestionId,
      disableButton,
      apianswer,
      apiquestions,
      isActiveeditField,
      answerValue,
      FormDataOpper,
      refState,
    } = this.state;

    // console.log("answer 239", answer);
    // console.log("FormDataOpper 239", FormDataOpper);

    const changeTab = (tab) => {
      if (tab == "tab0") {
        document.getElementById("tab_btn_0").classList.add("active");
        document.getElementById("tab0").classList.add("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }
      if (tab == "tab1") {
        document.getElementById("tab_btn_1").classList.add("active");
        document.getElementById("tab1").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }
      if (tab == "tab2") {
        document.getElementById("tab_btn_2").classList.add("active");
        document.getElementById("tab2").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }
      if (tab == "tab3") {
        document.getElementById("tab_btn_3").classList.add("active");
        document.getElementById("tab3").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }
      if (tab == "tab4") {
        document.getElementById("tab_btn_4").classList.add("active");
        document.getElementById("tab4").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");
      }
      // console.log(tab);
    };

    return (
      <div className="__review-card">
        <div className="__model" onClick={this.props.handleHide}></div>
        <div className="__pb_deal-card p-3">
          <div className="__pb_deal-profile"></div>
          <div className="__main">
            <div className="__deal_page_header">
              <div className="__header-left flex">
                <div className="__company-image">
                  <div>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={this.props.getAvatarUrl(
                        this.props.selected_company[0].company_files,
                        "company avatar"
                      )}
                    ></img>
                  </div>
                </div>
                <div className="__company-info">
                  <h3 className="__title">
                    {this.props.selected_company[0].company_name}
                  </h3>
                  <p className="__subtitle">
                    {this.props.selected_company[0].company_website &&
                      this.props.selected_company[0].company_website.length !==
                        0 && (
                        <a
                          href={
                            this.props.selected_company[0].company_website.includes(
                              "http"
                            )
                              ? this.props.selected_company[0].company_website
                              : `http://${this.props.selected_company[0].company_website}`
                          }
                          target="_blank"
                        >
                          {this.props.selected_company[0].company_website}
                        </a>
                      )}
                    {this.props.selected_company[0].company_website &&
                      this.props.selected_company[0].company_website.length !==
                        0 &&
                      this.props.selected_company[0].formatted_address &&
                      this.props.selected_company[0].formatted_address
                        .length !== 0}
                  </p>
                </div>
              </div>
              {/* <div class="" role="group" aria-label="Basic example">
  
                <button type="button" class="btn btn-sm btn-white">New</button>
                <button type="button" class="btn btn-sm btn-white">Reviewing</button>
                <button type="button" class="btn btn-sm btn-white">1st Diligence</button>
                <button type="button" class="btn btn-sm btn-white">Deep Diligence</button>
                <button type="button" class="btn btn-sm btn-white">Offer</button>
              </div> */}
              {/* <div className="__header-middle ml-2 border-3">
                <div className="__header-stages bg-black flex">
                  <div className="__stage">
                    New
                  </div>
                  <div className="__stage">
                    Reviewing
                  </div>
                  <div className="__stage">
                    1st Diligence
                  </div>
                  <div className="__stage">
                    Deep Diligence
                  </div>
                  <div className="__stage">
                    Offer
                  </div>
                </div>
              </div> */}
              <div className="__header-right mr-3">
                <div
                  className="close-icon-main-wraper h-4"
                  onClick={this.props.handleHide}
                >
                  <AiOutlineClose />
                </div>
              </div>
            </div>

            <div class="info_review_modal_inner">
              <div class="tearsheet-new">
                {/* Company cover image */}
                {this.props.selected_company[0].pitch_comp_video_link !==
                  undefined && this.props.opportunity_data.enter_pitch_video ? (
                  <iframe
                    src={this.props.selected_company[0].pitch_comp_video_link}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                ) : this.props.selected_company[0].background_video_link !==
                    undefined &&
                  this.props.selected_company[0].background_video_link !==
                    null &&
                  this.props.selected_company[0].background_video_link.length >
                    0 ? (
                  <iframe
                    src={this.props.selected_company[0].background_video_link}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                ) : (
                  <img
                    src={this.props.getAvatarUrl(
                      this.props.selected_company[0].company_files,
                      "company background",
                      this.props.selected_company[0].temp_background_url
                    )}
                    alt="hero image"
                  />
                )}

                <h4 className="__company-name">
                  <a
                    href={
                      "/profile?company_id=" +
                      this.props.selected_company[0].applicant_company
                    }
                    target="_blank"
                  >
                    {this.props.selected_company[0].company_name}
                  </a>
                </h4>

                {/* {console.log("this.props.selected_company",this.props?.selected_company[0])} */}
                {this.state.oppotunitydata &&
                  typeof this.state.oppotunitydata.opportunity_form ===
                    "object" &&
                  Object.keys(this.state.oppotunitydata.opportunity_form).map(
                    (opp_form_key, i) =>
                      this.state.oppotunitydata.opportunity_form[opp_form_key][
                        "display"
                      ] === true && (
                        <div class="mt-4">
                          <h6>
                            {
                              this.state.oppotunitydata.opportunity_form[
                                opp_form_key
                              ]["title"]
                            }
                          </h6>
                          <p>{this.props.selected_company[0][opp_form_key]}</p>
                        </div>
                      )
                  )}
                {console.log(
                  "updateStatus 720",
                  this.props.updateStatus?.application_status
                )}
                <div
                  variant="contained"
                  className="__status-dropdown stag-popup-deail"
                  onClick={(event) =>
                    this.props.openTableModal(this.props.selected_company[0])
                  }
                  size="small"
                >
                  <h6 class="mb-0 text-sm underline">
                    {this.props.updateStatus?.application_status ? (
                      <span>{this.props.updateStatus?.application_status}</span>
                    ) : (
                      <>
                        {!this.props.selected_company[0].application_status ? (
                          <span>New</span>
                        ) : null}
                        {this.props.selected_company[0].application_status ===
                          "new" && <span>New</span>}
                        {this.props.selected_company[0].application_status ===
                          "review" && <span>Review</span>}
                        {this.props.selected_company[0].application_status ===
                          "firstdiligence" && <span>First Diligence</span>}
                        {this.props.selected_company[0].application_status ===
                          "deepdilligence" && <span>Deep Diligence</span>}
                        {this.props.selected_company[0].application_status ===
                          "offer" && <span>Offer</span>}
                      </>
                    )}
                  </h6>
                </div>

                {typeof FormDataOpper?.deal_notes === "object" && (
                  //  FormDataOpper?.deal_notes.title
                  // Object.keys(FormDataOpper?.opportunity_form).map(
                  //   (opp_form_key, i) =>
                  // FormDataOpper?.opportunity_form[opp_form_key]["display"] === true && (
                  <div
                    className="border-2"
                    onTouchStart={() =>
                      this.setState({
                        isActiveeditField: FormDataOpper?.deal_notes,
                      })
                    }
                    onClick={() =>
                      this.setState({
                        isActiveeditField: FormDataOpper?.deal_notes,
                      })
                    }
                    onBlur={() =>
                      this.handleDealInfo(FormDataOpper?.deal_notes)
                    }
                  >
                    {isActiveeditField === FormDataOpper?.deal_notes ? (
                      <>
                        <TextField
                          // disabled
                          rows="4"
                          style={{ width: "100%", height: "100px" }}
                          variant="outlined"
                          multiline
                          value={answerValue}
                          onBlur={(event) => {
                            this.handleAnsDeal(
                              event,
                              FormDataOpper?.deal_notes
                            );
                            this.handleFormSave("deal_notes");
                          }}
                          placeholder={FormDataOpper?.deal_notes?.helper_text}
                        />
                        {/* <div className="button-edit-dealinfo">
                                <div className="button-save-dealinfo justify-en">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleFormSave("deal_notes")}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div> */}
                      </>
                    ) : (
                      <div paragraph>
                        {FormDataOpper?.deal_notes?.helper_text}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div
                container
                direction="row"
                alignItems="left"
                className="info_review_card_middle __content"
              >
                <div className="review_tabs_wrap">
                  <ul className="r_tab_nav">
                    <li>
                      <button
                        id="tab_btn_0"
                        type="button"
                        data-target="tab0"
                        className="active"
                        onClick={() => changeTab("tab0")}
                      >
                        Deal Info
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_1"
                        type="button"
                        data-target="tab1"
                        className=""
                        onClick={() => changeTab("tab1")}
                      >
                        Review Deal
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_2"
                        type="button"
                        data-target="tab2"
                        className=""
                        onClick={() => changeTab("tab2")}
                      >
                        Document Summary
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_3"
                        type="button"
                        data-target="tab3"
                        className=""
                        onClick={() => changeTab("tab3")}
                      >
                        DiligenceGPT
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_4"
                        type="button"
                        data-target="tab4"
                        className=""
                        onClick={() => changeTab("tab4")}
                      >
                        Reports
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_5"
                        type="button"
                        data-target="tab5"
                        className=""
                      >
                        <i
                          className="material-icons cancel-icon"
                          style={{
                            fontSize: "15px",
                            margin: 0,
                          }}
                          onClick={(event) =>
                            this.props.flagged_popup(
                              event,
                              this.props.selected_company[0]
                            )
                          }
                        >
                          delete
                        </i>
                      </button>
                    </li>
                  </ul>
                  <div className="r_tab_content active" id="tab0">
                    <div
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className="__content info_review_card_right"
                    >
                      <div className="w-100">
                        <div className="Deal-Info">
                          <h4>Deal Info</h4>
                          <p className="text-grey text-sm">
                            Business details and application
                          </p>
                          <div className=""></div>
                          <table className="table-fixed w-100 ">
                            <tbody>
                              <tr className="border-bottom border-gray-100 border-t">
                                <td className="py-4 font-bold text-sm">
                                  Business Name
                                </td>
                                <td className="py-4 text-sm">
                                  {this.props.selected_company[0].company_name}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t">
                                <td className="w-20 py-4 font-bold text-sm">
                                  Website
                                </td>
                                <td className="text-sm py-4">
                                  {this.props.selected_company[0]
                                    .company_website &&
                                    this.props.selected_company[0]
                                      .company_website.length !== 0 && (
                                      <a
                                        href={
                                          this.props.selected_company[0].company_website.includes(
                                            "http"
                                          )
                                            ? this.props.selected_company[0]
                                                .company_website
                                            : `http://${this.props.selected_company[0].company_website}`
                                        }
                                        target="_blank"
                                      >
                                        {
                                          this.props.selected_company[0]
                                            .company_website
                                        }
                                      </a>
                                    )}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t">
                                <td className="w-20 py-4 font-bold text-sm">
                                  Location
                                </td>
                                <td className="text-sm py-4">
                                  {this.props.selected_company[0]
                                    .company_website &&
                                    this.props.selected_company[0]
                                      .company_website.length !== 0 &&
                                    this.props.selected_company[0]
                                      .formatted_address &&
                                    this.props.selected_company[0]
                                      .formatted_address.length !== 0}
                                  {this.props.selected_company[0]
                                    .formatted_address &&
                                    this.props.selected_company[0]
                                      .formatted_address.length !== 0 && (
                                      <p
                                        className="text-sm"
                                        style={{ display: "inline" }}
                                      >
                                        {this.props.selected_company[0].city},{" "}
                                        {this.props.selected_company[0].country}
                                      </p>
                                    )}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t p-3">
                                <td className="w-20 py-4 font-bold text-sm align-top">
                                  Description
                                </td>
                                <td className="text-sm py-4">
                                  {this.props.selected_company[0].short_bio}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t p-3">
                                <td className="w-20 py-2 font-bold text-sm align-top py-4">
                                  Business Models
                                </td>
                                <td className="text-sm">
                                  <Chip
                                    variant="outlined"
                                    className={classnames(
                                      "exploreTag",
                                      "blueHl"
                                    )}
                                    color={"primary"}
                                    size="small"
                                    label={
                                      this.props.selected_company[0]
                                        .business_model
                                    }
                                  />
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t">
                                <td className="w-20 font-bold text-sm align-top py-4">
                                  Industries Disrupted
                                </td>
                                <td className="text-sm py-4">
                                  <div className="allTags">
                                    {this.props.selected_company[0].industry.map(
                                      (val) => (
                                        <Chip
                                          variant="outlined"
                                          className={classnames(
                                            "exploreTag",
                                            "greenHl"
                                          )}
                                          color={"primary"}
                                          size="small"
                                          label={val}
                                        />
                                      )
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t p-3">
                                <td className="w-20 py-2 font-bold text-sm align-top py-4">
                                  Data Room
                                </td>
                                <td className="text-sm py-4 mb-4">
                                  {this.state.pitchdeckUrl !== 0 && (
                                    <div className="documents p-3 w-100 flex justify-between">
                                      <i
                                        className="fa fa-paperclip text-gray"
                                        aria-hidden="true"
                                      ></i>
                                      <span className="py-2">
                                        Company Pitchdeck.pdf
                                      </span>
                                      <span className="">
                                        <a
                                          className="text-sm pl-5 font-bold"
                                          href={this.state.pitchdeckUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Download
                                        </a>
                                      </span>
                                    </div>
                                  )}

                                  <FormControl
                                    type="file"
                                    data-file-type="pitchdeck"
                                    placeholder="pitchdeck"
                                    accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                    name="pitchdeck"
                                    onChange={this.handleFileUpload}
                                    className="form-control mb-3"
                                  />
                                  <Button
                                    onClick={() =>
                                      this.handleFileSubmit("pitchdeckDetails")
                                    }
                                    className="mt-2 btn btn-md"
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.UploadingDocument}
                                  >
                                    {this.state.UploadingDocument
                                      ? "Uploading Document..."
                                      : "Upload Document"}
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="info_review_card_left r_tab_content" id="tab1">
                    <div class="row justify-content-md-center">
                      <div class="tearsheet">
                        {/* Company cover image */}
                        {this.props.selected_company[0]
                          .pitch_comp_video_link !== undefined &&
                        this.props.opportunity_data.enter_pitch_video ? (
                          <iframe
                            src={
                              this.props.selected_company[0]
                                .pitch_comp_video_link
                            }
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        ) : this.props.selected_company[0]
                            .background_video_link !== undefined &&
                          this.props.selected_company[0]
                            .background_video_link !== null &&
                          this.props.selected_company[0].background_video_link
                            .length > 0 ? (
                          <iframe
                            src={
                              this.props.selected_company[0]
                                .background_video_link
                            }
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        ) : (
                          <img
                            src={this.props.getAvatarUrl(
                              this.props.selected_company[0].company_files,
                              "company background",
                              this.props.selected_company[0].temp_background_url
                            )}
                            alt="hero image"
                          />
                        )}
                        {/* Company Profile Image */}
                        <div className="__company-image-wrapper">
                          <div>
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={this.props.getAvatarUrl(
                                this.props.selected_company[0].company_files,
                                "company avatar"
                              )}
                            ></img>
                          </div>
                        </div>
                        <div className="text-center">
                          <h4 className="__company-name">
                            <a
                              href={
                                "/profile?company_id=" +
                                this.props.selected_company[0].applicant_company
                              }
                              target="_blank"
                            >
                              {this.props.selected_company[0].company_name}
                            </a>
                          </h4>
                        </div>
                        <p className="text-center mb-0">
                          <Typography
                            variant="p"
                            color="textPrimary"
                            class="my-3"
                          >
                            {this.props.selected_company[0].company_website &&
                              this.props.selected_company[0].company_website
                                .length !== 0 && (
                                <a
                                  href={
                                    this.props.selected_company[0].company_website.includes(
                                      "http"
                                    )
                                      ? this.props.selected_company[0]
                                          .company_website
                                      : `http://${this.props.selected_company[0].company_website}`
                                  }
                                  target="_blank"
                                >
                                  {
                                    this.props.selected_company[0]
                                      .company_website
                                  }
                                </a>
                              )}
                            {this.props.selected_company[0].company_website &&
                              this.props.selected_company[0].company_website
                                .length !== 0 &&
                              this.props.selected_company[0]
                                .formatted_address &&
                              this.props.selected_company[0].formatted_address
                                .length !== 0 && <span> | </span>}
                            {this.props.selected_company[0].formatted_address &&
                              this.props.selected_company[0].formatted_address
                                .length !== 0 && (
                                <p style={{ display: "inline" }}>
                                  {this.props.selected_company[0].city},
                                  {this.props.selected_company[0].province},
                                  {this.props.selected_company[0].country}
                                </p>
                              )}
                          </Typography>
                        </p>
                        <div className="exploreCardTags mb-4">
                          <div className="allTags text-center">
                            <Chip
                              variant="outlined"
                              className={classnames("exploreTag", "")}
                              color={"default"}
                              size="small"
                              label={this.props.selected_company[0].country}
                            />
                            {this.props.selected_company[0].industry.map(
                              (val) => (
                                <Chip
                                  variant="outlined"
                                  className={classnames(
                                    "exploreTag",
                                    "greenHl"
                                  )}
                                  color={"primary"}
                                  size="small"
                                  label={val}
                                />
                              )
                            )}
                          </div>
                        </div>

                        {this.state.oppotunitydata &&
                          typeof this.state.oppotunitydata.opportunity_form ===
                            "object" &&
                          Object.keys(
                            this.state.oppotunitydata.opportunity_form
                          ).map(
                            (opp_form_key, i) =>
                              this.state.oppotunitydata.opportunity_form[
                                opp_form_key
                              ]["display"] === true && (
                                <div class="mt-4">
                                  <h6>
                                    {
                                      this.state.oppotunitydata
                                        .opportunity_form[opp_form_key]["title"]
                                    }
                                  </h6>
                                  <p>
                                    {
                                      this.props.selected_company[0][
                                        opp_form_key
                                      ]
                                    }
                                  </p>
                                </div>
                              )
                          )}
                        <div>
                          <Typography>Contact Info</Typography>
                          <p>
                            <span style={{ fontStyle: "italic" }}>Name: </span>{" "}
                            {this.props.selected_company[0].owner.first_name}{" "}
                            {this.props.selected_company[0].owner.last_name}{" "}
                          </p>
                          <p>
                            <span style={{ fontStyle: "italic" }}>Email: </span>{" "}
                            {this.props.selected_company[0].owner.email}{" "}
                          </p>
                          <p>
                            <span style={{ fontStyle: "italic" }}>#: </span>{" "}
                            {this.props.contact_phone}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="px-3 review-tab-main-view">
                        <h4>Review Deal</h4>
                        <p className="text-grey text-sm">
                          Leave a review and comment below
                        </p>
                        {this.state.url.pathname === "/applicant_room_top" ? (
                          <Fragment>
                            <div className="__star-ratings">
                              <h5 className="__company-name">
                                <a
                                  href={
                                    "/profile?company_id=" +
                                    this.props.selected_company[0]
                                      .applicant_company
                                  }
                                  target="_blank"
                                >
                                  {this.props.selected_company[0].company_name}
                                </a>
                              </h5>
                              <div className="__rating justify-content-center overallrating">
                                <div className="__reviews">
                                  <div className="__graphical-reviews">
                                    <StarRatings
                                      className="__stars"
                                      rating={this.props.totalOverall}
                                      starRatedColor="#C49733"
                                      numberOfStars={10}
                                      starDimension={20}
                                      starSpacing="0"
                                    />
                                  </div>
                                  <div className="__number-reviews overallscore">
                                    {this.props.totalOverall.toFixed(1)}
                                  </div>
                                  <hr />
                                  {/* <div className='__text-reviews'> {this.props.reviews.length} review(s).</div> */}
                                </div>
                              </div>
                              <br />
                              <Rating
                                title="Idea"
                                rating={this.props.totalIdea}
                              />
                              <Rating
                                title="Tech"
                                rating={this.props.totalTechnology}
                              />
                              <Rating
                                title="Team"
                                rating={this.props.totalTeam}
                              />
                              <Rating
                                title="Marketing"
                                rating={this.props.totalMarketing}
                              />
                              {this.props.opportunity_data
                                .additional_review_options &&
                                this.props.opportunity_data
                                  .additional_review_criteria !== undefined &&
                                this.props.opportunity_data
                                  .additional_review_criteria !== null &&
                                this.props.opportunity_data
                                  .additional_review_criteria.length > 0 &&
                                this.props.opportunity_data.additional_review_criteria.map(
                                  (criteria) => (
                                    <Rating
                                      title={criteria}
                                      rating={this.props.totalAdditionalReviewCriteria(
                                        criteria
                                      )}
                                    />
                                  )
                                )}
                            </div>
                            {/* Write Review
                                  <div className='__write-review-button' style={{width:"unset"}} onClick={this.props.handleToggle}>
                                      <i class="far fa-edit"></i>
                                      {hasReviewed ? 'Edit The' : 'Write A'} Review For This Company!
                                  </div>
                                  */}
                            <div className="comments">
                              {this.props.reviews.map((e, i) => {
                                return (
                                  <Comment
                                    data={e}
                                    getAvatarUrl={this.getAvatarUrl}
                                  />
                                );
                              })}
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <span class="mt-4" style={{ textAlign: "left" }}>
                              {hasReviewed ? "Edit Your" : "Write A"} Review
                            </span>
                            <br />
                            <div className="w-50">
                              <Rating
                                title="Idea | Problem & Solution"
                                name="idea"
                                rating={this.props.idea}
                                changeRating={this.props.changeRating}
                              />
                              <TextField
                                id="filled-multiline-flexible"
                                label=""
                                multiline
                                placeholder="Provide rating and feedback on the idea of the company. Review the problem they are solving, the solution they are providing, and the market opportunity."
                                rows="4"
                                value={this.props.idea_comment}
                                onChange={this.props.handleChange}
                                name="idea_comment"
                                margin="normal"
                                variant="filled"
                                fullWidth
                              />
                              <Rating
                                title="Product/Service  "
                                name="technology"
                                rating={this.props.technology}
                                changeRating={this.props.changeRating}
                              />
                              <TextField
                                id="filled-multiline-flexible"
                                label=""
                                multiline
                                placeholder="Provide rating and feedback on the product/service of the company. Review the product's features, the product's uniqueness, and the product's scalability."
                                rows="4"
                                value={this.props.technology_comment}
                                onChange={this.props.handleChange}
                                name="technology_comment"
                                margin="normal"
                                variant="filled"
                                fullWidth
                              />
                              <Rating
                                title="Team | Human Capital & Talent"
                                name="team"
                                rating={this.props.team}
                                changeRating={this.props.changeRating}
                              />
                              <TextField
                                id="filled-multiline-flexible"
                                label=""
                                multiline
                                placeholder="Provide rating and feedback on the team of the company. Review the team's experience, the team's ability to execute, and the team's ability to scale."
                                rows="4"
                                value={this.props.team_comment}
                                onChange={this.props.handleChange}
                                name="team_comment"
                                margin="normal"
                                variant="filled"
                                fullWidth
                              />
                              <Rating
                                title="Marketing | Customer Acquisition & Gotomarket Strategy"
                                name="marketing"
                                rating={this.props.marketing}
                                changeRating={this.props.changeRating}
                              />
                              <TextField
                                id="filled-multiline-flexible"
                                label=""
                                multiline
                                placeholder="Provide rating and feedback on the marketing of the company. Review the company's marketing strategy, the company's ability to acquire customers, and the company's ability to retain customers."
                                rows="4"
                                value={this.props.marketing_comment}
                                onChange={this.props.handleChange}
                                name="marketing_comment"
                                margin="normal"
                                variant="filled"
                                fullWidth
                              />
                              {this.props.opportunity_data
                                .additional_review_options &&
                                this.props.opportunity_data
                                  .additional_review_criteria !== undefined &&
                                this.props.opportunity_data
                                  .additional_review_criteria !== null &&
                                this.props.opportunity_data
                                  .additional_review_criteria.length > 0 &&
                                this.props.opportunity_data.additional_review_criteria.map(
                                  (criteria) => (
                                    <>
                                      <Rating
                                        rating={
                                          this.props
                                            .additional_review_criteria !==
                                            undefined &&
                                          this.props.additional_review_criteria[
                                            criteria
                                          ] !== undefined
                                            ? this.props
                                                .additional_review_criteria[
                                                criteria
                                              ]
                                            : 0
                                        }
                                        changeRating={
                                          this.props
                                            .handleChangeAdditionalRating
                                        }
                                        name={criteria}
                                        title={criteria}
                                      />
                                      <TextField
                                        id="filled-multiline-flexible"
                                        label=""
                                        multiline
                                        placeholder="Provide a rating and feedback on the company's regarding this custom criteria"
                                        rows="4"
                                        value={
                                          this.props
                                            .additional_review_criteria_comment !==
                                            undefined &&
                                          this.props
                                            .additional_review_criteria_comment[
                                            criteria
                                          ] !== undefined
                                            ? this.props
                                                .additional_review_criteria_comment[
                                                criteria
                                              ]
                                            : ""
                                        }
                                        onChange={
                                          this.props
                                            .handleChangeAdditionalRatingComment
                                        }
                                        name={criteria}
                                        margin="normal"
                                        variant="filled"
                                        fullWidth
                                      />
                                    </>
                                  )
                                )}{" "}
                              <hr />
                              {/* <TextField
                                              id="filled-multiline-flexible"
                                              label="Comment"
                                              multiline
                                              placeholder="Only visible to the other judges, this will not be seen by the applicant."
                                              rows="4"
                                              value={this.props.comment}
                                              onChange={this.props.handleChange}
                                              name="comment"
                                              margin="normal"
                                              variant="filled"
                                              fullWidth
                                          /> */}
                              <TextField
                                id="filled-multiline-flexible"
                                label="Feedback"
                                multiline
                                rows="4"
                                placeholder="Information written here will be viewable by the deal owner and is a way to provide feedback for improvement."
                                value={this.props.feedback}
                                onChange={this.props.handleChange}
                                name="feedback"
                                margin="normal"
                                variant="filled"
                                fullWidth
                              />
                              <Rating
                                className="__total-ratings"
                                title="Overall rating"
                                name="overall"
                                rating={
                                  this.props.pitchOverallRating !== undefined &&
                                  this.props.pitchOverallRating !== null
                                    ? this.props.pitchOverallRating
                                    : this.props.overallRating
                                }
                              />
                              <Button
                                onClick={
                                  hasReviewed
                                    ? this.props.editReviewSubmit
                                    : this.props.reviewSubmit
                                }
                                variant="outlined"
                                color="primary"
                                className="__submit-review-btn"
                                fullWidth
                              >
                                Submit Review
                              </Button>
                            </div>
                            <div></div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="r_tab_content" id="tab2">
                    <div className="px-3">
                      <h4>Document Summary</h4>
                      <p className="text-grey text-sm">
                        Add pre-set diligence questions, upload documents, and
                        summarize below
                      </p>
                      {disableButton ? (
                        <div class="__submission-page">
                          <div class="SocialLoadingIcon">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="128"
                              height="32"
                              viewBox="0 0 128 32"
                            >
                              <path d="M112-0.008c-8.84 0-16 7.16-16 16 0 8.832 7.16 15.992 16 15.992s16-7.16 16-15.992c0-8.84-7.16-16-16-16zM64-0.008c-8.84 0-16 7.16-16 16 0 8.832 7.16 15.992 16 15.992s16-7.16 16-15.992c0-8.84-7.16-16-16-16zM16-0.008c-8.832 0-16 7.16-16 16s7.168 15.992 16 15.992 16-7.16 16-15.992c0-8.84-7.16-16-16-16z"></path>
                            </svg>
                          </div>
                        </div>
                      ) : apiquestions?.length ? (
                        <>
                          <div className="flex justify-between">
                            {" "}
                            {/* Adjusted for space-between */}
                            <div className="mr-4">
                              {" "}
                              {/* Add right margin to the first button */}
                              <button
                                onClick={this.handleSummarizeAll}
                                disabled={this.state.isSummarizing}
                                className="bg-blue-600 hover:bg-blue-800 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              >
                                {this.state.isSummarizing
                                  ? "Summarizing..."
                                  : "Summarize"}
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={this.redirectToAddQuestion}
                                className="bg-green-600 hover:bg-green-800 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              >
                                Add Questions
                              </button>
                            </div>
                          </div>
                          {apiquestions.map((question) => (
                            <div className="r_faq_item" key={question.id}>
                              <h5>{question.question}</h5>
                              <p
                                className={`${
                                  apianswer && apianswer[question._id]
                                    ? "answer-add-q"
                                    : null
                                }`}
                              >
                                {apianswer && apianswer[question._id]}
                              </p>
                              {/* <div className='f_faq_item_action'>
                                                  <button onClick={() => this.handleSummarizeAll()}>Summarize</button>
                                                </div> */}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>
                          <h3>Question Not Found</h3>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="r_tab_content tabchatgpt-main" id="tab3">
                    <div className="px-3">
                      <h4>DiligenceGPT</h4>
                      <p className="text-grey text-sm">
                        Ask questions directly to the data room, and deal info
                      </p>
                      <form
                        className="r_faq_form"
                        onSubmit={
                          editingQuestionId
                            ? this.handleUpdateQuestion
                            : this.handleAddQuestion
                        }
                      >
                        <div class="r_faq_question">
                          {console.log("question", question)}
                          <input
                            type="text"
                            value={question}
                            onChange={this.handleQuestionChange}
                            placeholder="Ask DiligenceGPT questions about this deal..."
                            class="input-field text-sm"
                          />
                          <button
                            type="submit"
                            data-question={question}
                            class="btn"
                            onClick={(event) =>
                              this.handleSummarizeNew(
                                event,
                                questions.length + 1
                              )
                            }
                            disabled={!question}
                          >
                            <i class="fas fa-arrow-right"></i>
                          </button>
                        </div>

                        <div className="form-chatgpt-layout">
                          <div class="faq_answer">
                            {/* {console.log("questions 1492",questions)} */}
                            {questions.map((question) => (
                              <div className="r_faq_item" key={question.id}>
                                <div className="faq_question">
                                  <div className="question-main-wraper-m">
                                    <div className="question-main-wraper-m-u">
                                      <p>
                                        {this.props?.selected_company[0]?.company_name?.charAt(
                                          0
                                        )}
                                      </p>
                                    </div>
                                    <div className="question-main-wraper-m-t">
                                      <h4>You</h4>
                                      <p>{question.question}</p>
                                    </div>
                                  </div>
                                  {question.id === editingQuestionId ? (
                                    <div className="faq_edit_section">
                                      <input
                                        className="border-none"
                                        type="text"
                                        value={question.question}
                                        onChange={(event) =>
                                          this.handleQuestionChange(
                                            event,
                                            question.id
                                          )
                                        }
                                        placeholder="Edit question..."
                                      />
                                      <input
                                        className="border-none"
                                        type="text"
                                        value={answer}
                                        onChange={(event) =>
                                          this.handleAnswerChange(
                                            event,
                                            question.id
                                          )
                                        }
                                        placeholder="Edit answer..."
                                      />
                                    </div>
                                  ) : (
                                    <div className="answer-main-wraper-a">
                                      <div className="answer-main-wraper-u">
                                        <img
                                          src={require("../../../../img/favicon.png")}
                                        />
                                      </div>
                                      <div className="answer-main-wraper-t">
                                        <h4>DiligenceGPT</h4>
                                        <p>
                                          {question.answer
                                            ? question.answer
                                            : "Waiting..."}
                                        </p>
                                        <div className="delete-question-m">
                                          {question.answer && (
                                            <p
                                              onClick={() =>
                                                this.handleDeleteQuestion(
                                                  question.id
                                                )
                                              }
                                            >
                                              <i class="fas fa-trash"></i>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="r_tab_content" id="tab4">
                    <div className="px-3">
                      <h4>Reports</h4>
                      <p className="text-grey text-sm">
                        Generate, edit & download deal reports
                      </p>
                      {/* <TearsheetPdf
                      tamplateData={this.props?.selected_company[0]}
                      dealInfoDetial={
                        this.props.opportunity_data.opportunity_form
                      }
                    /> */}
                      <div className="download-tear-sheet">
                        {console.log(
                          "setUpdateTearsheet",
                          this.state.UpdateTearsheet
                        )}
                        {this.state.UpdateTearsheet && (
                          <p className="color-red">
                            {this.state.UpdateTearsheet}
                          </p>
                        )}
                        <div className="download-btn-main">
                          <div className="update-tear-sheet">
                            {/* <button onClick={this.handleButtonClick}>
                              Update
                            </button> */}
                            <button onClick={this.handleClickGenerate} disabled={this.state.ocrloader}>
                              Generate
                            </button>
                            <div className="update-tearsheet-text"></div>
                          </div>
                          <ReactToPrint
                            trigger={() => {
                              return <a href="#">Download Tear Sheet</a>;
                            }}
                            content={() => this.componentRef}
                          />
                        </div>
                        <ComponentToPrint
                          tamplateData={this.props?.selected_company[0]}
                          dealInfoDetial={
                            this.props.opportunity_data.opportunity_form
                          }
                          ref={(el) => (this.componentRef = el)} // Ref to child component instance
                          setUpdateTearsheet={this.setUpdateTearsheet} // Pass the function as a prop
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
