/* (2019-06-10) - This is the page that appears after a user registers using
  the register link that appears in the menu.
*/
import React from 'react';
import '../main.css';
import PropTypes from 'prop-types';
import URL from 'url-parse';
import Navigation from './components/navbar'
import SiteTitle from './components/site_title.js'
import { Sign_In, Register, Forgot_Password } from './components/popups'
import { AppContext } from '../App'
import { withStyles } from '@material-ui/core/styles';
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';
import './welcomeUBaid.css';
import DealsIcon from '@material-ui/icons/LibraryAdd';
import TeamIcon from '@material-ui/icons/People';
import ReviewIcon from '@material-ui/icons/StarHalf';
import SettingsIcon from '@material-ui/icons/Settings';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Create_User_Company_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (
      <Create_User_Company
        {...this.props}
        {...this.context} />
    )
  }
}

class Create_User_Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      email: '',
      password: '',
      first_name: "",
      last_name: '',
      company_name: "",
      company_type: "",
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value
    }, () => {
      if (name === "address") {
        this.getLocation()
      }
    })
  }

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = []
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values
    })
  }
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text
      }
    }
  }


  render() {
    let company_owned = this.props.companies_owned!==undefined && this.props.companies_owned!==null && this.props.companies_owned[1]!==undefined && this.props.companies_owned[1]!==null?this.props.companies_owned[1][0].company_type[0]:""
    return (

      <React.Fragment>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Register />
        <Forgot_Password />
        <div class="container ubaidWelcomePageParent">
          <div class="main ubaidWelcomePage">
            <div class="panel-heading">
              <div class="panel-title text-center">
                <h1 class="title" style={{ fontWeight: "500" }}>Welcome to StartupFuel</h1>
                {(company_owned  ==="Startup"||company_owned ==="Tech Startup") &&
                <>
                <div style={{ fontStyle: "italic" }} className='StartupAimUBaid'>Our aim is to reduce the startup failure rate by ensuring that founders have access to relevant opportunities and resources all in one place. Our StartupFuel platform was built for you to connect & engage with the startup community while cutting out the noise from other mainstream social networks and platforms in this space.</div>
                <h3 style={{ fontWeight: "400" }}> Getting Started:</h3>

                  <div className='WelcomePageDescr'>
                    <div className='WelcomeUBaidTdSr'>1.</div>
                    <div>Finish creating your <Link style={{ color: "#007bff" }} to={this.props.companies_owned !== null && this.props.companies_owned[1] !== null ? `/profile?company_id=${this.props.companies_owned[1][0]._id}` : ""}>company profile</Link>. You can view your <Link style={{ color: "#007bff" }} to={this.props.companies_owned !== null && this.props.companies_owned[1] !== null ? `/profile?company_id=${this.props.companies_owned[1][0]._id}` : ""}>profile</Link> in the dropdown menu in the top right.</div>
                  </div>
                  {this.state.url.query.community_id=== undefined?
                    <>
                  <div className='WelcomePageDescr'>
                    <div className='WelcomeUBaidTdSr'>2.</div>
                    <div>Browse and apply to <Link style={{ color: "#007bff" }} to={'/opportunities'}>Startup Pitch Competitions </Link> and other relevant opportunities that will help you grow.</div>
                  </div>
                  <div className='WelcomePageDescr'>
                    <div className='WelcomeUBaidTdSr'>3.</div>
                    <div>Received feedback from experts or know the areas you need help with most? You can now hire consultants and agencies directly on our  <Link style={{ color: "#007bff" }} to={'/services'}> Service Providers </Link> page. Reach out to us for a free consultation on your business!</div>
                  </div>
                  
                </>

                :

                <>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>2.</div>
                  <div>View the <Link style={{ color: "#007bff" }} to={`/community_members?community_id=${this.state.url.query.community_id}`}>Community</Link> you were invited to.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>3.</div>
                  <div>Browse and apply to <Link style={{ color: "#007bff" }} to={'/opportunities'}>Startup Pitch Competitions </Link> and other relevant opportunities that will help you grow.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>4.</div>
                  <div>Received feedback from experts or know the areas you need help with most? You can now hire consultants and agencies directly on our  <Link style={{ color: "#007bff" }} to={'/services'}> Service Providers </Link> page. Reach out to us for a free consultation on your business!</div>
                </div>
              </>

              }
                </>
                }
                {(company_owned  ==="Service Provider") &&
                <>
                <div style={{ fontStyle: "italic" }} className='StartupAimUBaid'>Our aim is to help you list your service in front of relevant eyeballs to generate greater leads. The mission is to reduce the startup failure rate by ensuring that founders have access to relevant opportunities and resources all in one place. Our StartupFuel platform was built for you to connect & engage with the startup community while cutting out the noise from other mainstream social networks and platforms in this space.</div>
                <h3 style={{ fontWeight: "400" }}> Getting Started:</h3>


                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>1.</div>
                  <div>Finish creating your <Link style={{ color: "#007bff" }} to={this.props.companies_owned !== null && this.props.companies_owned[1] !== null ? `/profile?company_id=${this.props.companies_owned[1][0]._id}` : ""}>company profile</Link>. You can view your <Link style={{ color: "#007bff" }} to={this.props.companies_owned !== null && this.props.companies_owned[1] !== null ? `/profile?company_id=${this.props.companies_owned[1][0]._id}` : ""}>profile</Link> in the dropdown menu in the top right.</div>
                </div>
                {this.state.url.query.community_id=== undefined?
                  <>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>2.</div>
                  <div>Add your service and submit it for approval. Once approved, you will need to setup a Stripe account found in your settings. We encourage you to invite startups you have worked with in the past to review your company in order to build greater credibility. </div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>3.</div>
                  <div>You may now message companies and respond to project requests. Transactions are to be made on StartupFuel.com and breaching this will result in being delisted.</div>
                </div>
                </>

                :

                <>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>2.</div>
                  <div>View the <Link style={{ color: "#007bff" }} to={`/community_members?community_id=${this.state.url.query.community_id}`}>Community</Link> you were invited to.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>3.</div>
                  <div>Add your service and submit it for approval. Once approved, you will need to setup a Stripe account found in your settings. We encourage you to invite startups you have worked with in the past to review your company in order to build greater credibility. </div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>4.</div>
                  <div>You may now message companies and respond to project requests. Transactions are to be made on StartupFuel.com and breaching this will result in being delisted.</div>
                </div>
              </>

              }
                </>
                }
                {(company_owned  ==="Investor") &&
                <>
                <div className='StartupAimUBaid'>Supercharge your startup investing with the PitchBox. </div>
                <div class="pb_dash">
                </div>
                <div class="opp-create-btn">
                  <a
                    href='/create_opportunity'
                    class="btn btn-primary">Launch A PitchBox</a>
                </div>
            
                <h3 style={{ fontWeight: "100", fontSize:"20px"}}> What is a PitchBox?</h3>
                <div className='title'>
                  <div>A smart workspace for Funds, Programs, and Competitions within you will be able to:</div>
                </div>
                  <div className='welcome-container'>
                  <div className='WelcomePageActions'>
                    <div className='icon_circle'><DealsIcon style={{ fontSize: "4rem"}} />
                    </div>
                    <h5>Add Deals</h5>
                    <div>Add deals you are tracking, scout deals, and invite team & partners to add deals</div>
                  </div>
                  <div className='WelcomePageActions'>
                  <div className='icon_circle'><TeamIcon style={{ fontSize: "4rem"}} />
                    </div>
                    <h5>Manage Team</h5>
                    <div>Invite and manage team members (partners, analysts, investors, judges, advisors, mentors) </div>
                  </div>
                  <div className='WelcomePageActions'>
                  <div className='icon_circle'><ReviewIcon style={{ fontSize: "4rem"}} />
                    </div>
                    <h5>Review Deals </h5>
                    <div>Encourage team members to add, review and shortlist deals for deeper diligence </div>
                  </div>
                  <div className='WelcomePageActions'>
                  <div className='icon_circle'><SettingsIcon style={{ fontSize: "4rem"}} />
                    </div>
                    <h5>Customize </h5>
                    <div>Extensive PitchBox settings to fit your specific needs from data requested to review criteria  </div>
                  </div>
                </div>
              
               

                </>
                }
                {(company_owned  !=="Investor" && company_owned  !=="Startup" && company_owned  !=="Tech Startup" && company_owned  !=="Service Provider" ) &&
                <>
                <div style={{ fontStyle: "italic" }} className='StartupAimUBaid'>Our aim is to reduce the startup failure rate by ensuring that founders have access to relevant opportunities and resources all in one place. Our StartupFuel platform was built for you to connect & engage with the startup community while cutting out the noise from other mainstream social networks and platforms in this space. </div>
                <h3 style={{ fontWeight: "400" }}> Getting Started:</h3>


                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>1.</div>
                  <div>Finish creating your <Link style={{ color: "#007bff" }} to={this.props.companies_owned !== null && this.props.companies_owned[1] !== null ? `/profile?company_id=${this.props.companies_owned[1][0]._id}` : ""}>company profile</Link>. You can view your <Link style={{ color: "#007bff" }} to={this.props.companies_owned !== null && this.props.companies_owned[1] !== null ? `/profile?company_id=${this.props.companies_owned[1][0]._id}` : ""}>profile</Link> in the dropdown menu in the top right.</div>
                </div>
                {this.state.url.query.community_id=== undefined?
                  <>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>2.</div>
                  <div>Under your profile, click on the 'opportunities' tab to launch your initiative. </div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>3.</div>
                  <div>Get in touch to launch a 'PitchBox' - run intake, review startups and advertise your application on StartupFuel, email us at pitchbox@startupfuel.com.</div>
                </div>
                </>

                :

                <>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>2.</div>
                  <div>View the <Link style={{ color: "#007bff" }} to={`/community_members?community_id=${this.state.url.query.community_id}`}>Community</Link> you were invited to.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>2.</div>
                  <div>Under your profile, click on the 'opportunities' tab to launch your initiative. </div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>3.</div>
                  <div>Get in touch to launch a 'PitchBox' - run intake, review startups and advertise your application on StartupFuel, email us at pitchbox@startupfuel.com.</div>
                </div>
              </>

              }
                </>
                }



              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
Create_User_Company_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_User_Company_Container);
