/* (2019-06-11) - These are the filters that are seen on the submissions page.
Mainly just used for the specialty dropdown that allows judges to search companies
based off of their specialty.
*/
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class NativeSelects extends React.Component {
  state = {
    sortby: '',
    name: '',
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container justify="flex-end" alignItems="flex-start" className="d-sm-flex">
        <div className={classes.root}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="outlined-sortby-native-simple"
              style={{zIndex: 1, lineHeight: 0}}
            >
              Filter By:
            </InputLabel>
            <Select
              native
              value={this.props.sortby}
              onChange={this.props.handleChange}
              style={{background: '#fff', height: '38px'}}
              input={
                <OutlinedInput
                  name="sortby"
                  labelWidth={this.state.labelWidth}
                  id="filter-fix"
                />
              }
            >
              <option value="" />
              <option value={"judge_specialty"}>Specialty</option>
            </Select>
          </FormControl>
        </div>
      </Grid>
    );
  }
}

NativeSelects.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NativeSelects);
